import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import axios from "axios";
import { useAuthenticator } from "@aws-amplify/ui-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid #EBEBEB",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const UpgradeToProModal = ({ open, onClose }) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const handleCloseButtonClicked = () => {
    onClose();
  };
  const handleUpgradeButtonClicked = () => {
    // const stripeTestToken = "sk_test_51JKNRGLBdZq2LONlqFgMGSxvoAWmOCRYMrC8ccYjioJJETdvv4pGoyPYJRRc5LY3kUe1Ut2RNmJ8aZjvW1hEnhwZ00TawQoci5"
    const stripeLiveToken = "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";
    var qs = require("qs");
    var data = qs.stringify({
      success_url: "https://admin.fitprint.io/fitPrintProPurchaseSuccess?session_id={CHECKOUT_SESSION_ID}",
      cancel_url: "https://admin.fitprint.io/",
      "line_items[0][price]": "price_1LzrC9LBdZq2LONlTXiHePxW",
      "line_items[0][quantity]": "1",
      mode: "payment",
      'metadata[userId]': user.attributes.sub
    });
    var config = {
      method: "post",
      url: "https://api.stripe.com/v1/checkout/sessions",
      headers: {
        Authorization: `Bearer ${stripeLiveToken}`,
        ContentType: "application/x-www-form-urlencoded"
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        window.location.href = response.data.url;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upgrade To Pro
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            If you would like to remove the sponsored links from your site, you
            can upgrade to our Pro Version! It is a{" "}
            <b>one time fee for $9.95</b>. As always you can reach out to us at{" "}
            <Link href="mailto:admin@fitprint.io" underline="hover">
              {"admin@fitprint.io"}
            </Link>{" "}
            with any questions.
          </Typography>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "20px" }}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseButtonClicked}
                >
                  Close
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  startIcon={<VerifiedUserIcon />}
                  color="success"
                  onClick={handleUpgradeButtonClicked}
                >
                  Upgrade For $9.95
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default UpgradeToProModal;
