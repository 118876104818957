import React, { useState, useEffect } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ButtonAppBar from "../components/navBar";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Amplify, { API } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import axios from "axios";
import Grid from "@mui/material/Grid";

const FitPrintProPurchaseSuccess = (props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const { grantCode } = useParams();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const stripeTestToken =
      "sk_test_51JKNRGLBdZq2LONlqFgMGSxvoAWmOCRYMrC8ccYjioJJETdvv4pGoyPYJRRc5LY3kUe1Ut2RNmJ8aZjvW1hEnhwZ00TawQoci5";
    const stripeLiveToken =
      "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";
    var config = {
      method: "get",
      url: `https://api.stripe.com/v1/checkout/sessions/${searchParams.get(
        "session_id"
      )}`,
      headers: {
        Authorization: `Bearer ${stripeTestToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (
          response.data.metadata.userId === user.attributes.sub &&
          response.data.payment_status === "paid"
        ) {
          API.get(
            "FitPrintAPI",
            `/private/object/SHOP.${user.attributes.sub}/ACCOUNT.INFO`
          )
            .then((userResponse) => {
              var proUser = userResponse;
              proUser.showSponsoredLinks = false;
              const myInit = {
                body: proUser,
              };
              API.post(
                "FitPrintAPI",
                `/private/updateAccount/${user.attributes.sub}`,
                myInit
              )
                .then((updateUserResponse) => {
                  navigate('/')
                })
                .catch((updateUserError) => {
                  setIsLoaded(true);
                  setError(updateUserError);
                  alert(updateUserError.response);
                });
            })
            .catch((userError) => {
              setError(userError);
              alert(userError.response);
            });
        }
      })
      .catch(function (error) {
        setError(error);
        alert(error.response);
      });
  }, []);

  if (error) {
    return <div>Error</div>;
  }
  if (!isLoaded) {
    return (
      <>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          sx={{ marginTop: "40px" }}
          textAlign="center"
        >
          <Grid>
            <Grid item xs={12}>
              <Typography sx={{ p: 2 }}>
                We are processing your purchase, please do not close or navigate
                away from this window...
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }

  if (isSuccess) {
    return (
      <>
        <ButtonAppBar />
        <CssBaseline />
        <Container
          maxWidth="lg"
          sx={{
            minWidth: "1155px",
            padding: "10px",
          }}
        >
          <Typography sx={{ p: 2 }}>
            session_id = {searchParams.get("session_id")}
          </Typography>
        </Container>
      </>
    );
  }
};
export default FitPrintProPurchaseSuccess;
