import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import useStore from "../store.js";
import ColorPicker from "../components/colorPicker";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import uuid from "react-native-uuid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MoneyInput from "@rschpdr/react-money-input/dist/index.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "1px solid #EBEBEB",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  alignItems: "center",
  marginTop: "6px",
  marginLeft: "10px",
  color: theme.palette.text.secondary,
}));

const AddVariantModal = ({ open, onClose }) => {
  const [amount, setAmount] = useState(0);

function handleChange(e) {
  setAmount(e.target.value);
}
  const handleCloseButtonClicked = () => {
    setVariantTitle("");
    onClose();
  };

  const handleColorSelect = (event: SelectChangeEvent) => {
    console.log(event)
    setSelectedColor(event.target.value);
    console.log(selectedColor)
  };
  const handleSizeSelect = (event: SelectChangeEvent) => {
    setSelectedSize(event.target.value);
    console.log(selectedSize)
  };

  const renderSizeCheckbox = useCallback((size, index) => {
    return (
      <MenuItem value={size.id}>
        <Stack
          direction="row"
          justifyContent="left"
          alignItems="center"
          display="flex"
          textAlign="center"
          spacing={2}
        >
          <Typography variant="body1">{size.title}</Typography>
        </Stack>
      </MenuItem>
    );
  }, []);

  const renderColorCheckbox = useCallback((color, index) => {
    return (
      <MenuItem value={color.id}>
        <Stack
          direction="row"
          justifyContent="left"
          alignItems="center"
          display="flex"
          textAlign="center"
          spacing={2}
        >
          <div
            style={{
              border: "1px solid grey",
              width: "25px",
              height: "25px",
              backgroundColor: color.colors[0],
            }}
          ></div>
          <Typography variant="body1" sx={{ paddingLeft: "4px" }}>
            {color.title}
          </Typography>
        </Stack>
      </MenuItem>
    );
  }, []);

  const [variantTitle, setVariantTitle] = React.useState("");
  const [variantPrice, setVariantPrice] = React.useState("");
  const localCustomFitPrintProduct = useStore(
    (state) => state.localCustomFitPrintProduct
  );
  const updateLocalProduct = useStore(
    (state) => state.setLocalCustomFitPrintProduct
  );
  const sizes = useStore(
    (state) =>
      state.localCustomFitPrintProduct.options.find(
        (option) => option.type === "size"
      ).values
  );
  const colors = useStore(
    (state) =>
      state.localCustomFitPrintProduct.options.find(
        (option) => option.type === "color"
      ).values
  );
  const [selectedColor, setSelectedColor] = React.useState(null);
  const [selectedSize, setSelectedSize] = React.useState(null);

  const variants = useStore(
    (state) => state.localCustomFitPrintProduct.variants
  );

  function handleVariantTitleChange(event) {
    setVariantTitle(event.target.value);
  }

  function handleVariantPriceChange(event) {
    setVariantPrice(event.target.value);
  }

  function handleSaveButtonClicked() {
    let updatedLocalProduct = localCustomFitPrintProduct;
    console.log(localCustomFitPrintProduct)
    console.log([selectedColor, selectedSize])
    updatedLocalProduct.variants = [
      ...variants,
      {
        id: uuid.v4(),
        sku: uuid.v4(),
        price: variantPrice,
        title: variantTitle,
        is_enabled: isEnabled,
        is_default: isDefault,
        is_available: isAvailable,
        options: [selectedColor, selectedSize]
      },
    ];
    updateLocalProduct(updatedLocalProduct);
    setVariantTitle("");
    onClose();
  }

  const [variantStatusState, setVariantStatusState] = React.useState({
    isDefault: false,
    isEnabled: false,
    isAvailable: false,
  });

  const handleVariantStatusChange = (event) => {
    setVariantStatusState({
      ...variantStatusState,
      [event.target.name]: event.target.checked,
    });
  };

  const { isDefault, isEnabled, isAvailable } = variantStatusState;

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Container>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create A Variant
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={8} sx={{ marginTop: "10px" }}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="VariantTitleText"
                  label="Variant Title"
                  value={variantTitle}
                  onChange={handleVariantTitleChange}
                />{" "}
                <MoneyInput
                  customInput={TextField}
                  variant="outlined"
                  label="Custom Input!"
                  onChange={handleChange}
                  value={amount}
                />
                <TextField
                  margin="normal"
                  id="PriceText"
                  label="Price"
                  value={variantPrice}
                  onChange={handleVariantPriceChange}
                />{" "}
              </Grid>
              <Grid item xs={4} sx={{ marginTop: "10px" }}>
                <FormControl
                  sx={{ m: 3 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormLabel component="legend">Variant Status</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDefault}
                          onChange={handleVariantStatusChange}
                          name="isDefault"
                        />
                      }
                      label="Default"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isEnabled}
                          onChange={handleVariantStatusChange}
                          name="isEnabled"
                        />
                      }
                      label="Enabled"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isAvailable}
                          onChange={handleVariantStatusChange}
                          name="isAvailable"
                        />
                      }
                      label="Available"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Set Options
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ marginTop: "10px" }}>
                <Item>
                  <FormControl sx={{ minWidth: 125 }}>
                    <InputLabel id="demo-simple-select-label">Size</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      autoWidth
                      value={selectedSize}
                      label="Color"
                      onChange={handleSizeSelect}
                    >
                      {sizes.map((size, i) => renderSizeCheckbox(size, i))}
                    </Select>
                  </FormControl>
                </Item>
              </Grid>
              <Grid item xs={6} sx={{ marginTop: "10px" }}>
                <Item>
                  <FormControl sx={{ minWidth: 125 }}>
                    <InputLabel id="demo-simple-select-label">Color</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      autoWidth
                      value={selectedColor}
                      label="Color"
                      onChange={handleColorSelect}
                    >
                      {colors.map((color, i) => renderColorCheckbox(color, i))}
                    </Select>
                  </FormControl>
                </Item>
              </Grid>
            </Grid>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              textAlign="center"
              sx={{ marginTop: "20px" }}
            >
              <Button
                variant="contained"
                onClick={handleCloseButtonClicked}
                sx={{ marginRight: "10px" }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                onClick={handleSaveButtonClicked}
                sx={{ marginLeft: "10px" }}
              >
                Save
              </Button>
            </Box>
          </Container>
        </Box>
      </Modal>
    </div>
  );
};

export default AddVariantModal;
