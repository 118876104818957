import update from 'immutability-helper'
import { useCallback, useState } from 'react'
import { Card } from './card.js'
import useStore from '../store.js';

const style = {
  width: 550,
}
export const Container = () => {
    const userLinks = useStore((state) => state.userLinks);
    const removeArray = useStore((state) => state.removeUserLink);
    const moveArray = useStore((state) => state.moveUserLink);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
      moveArray(dragIndex, hoverIndex)
    }, [])

    const deleteCard = useCallback((index) => {
    removeArray(index)
    }, [])

    const renderCard = useCallback((card, index) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          title={card.title}
          url={card.url}
          moveCard={moveCard}
          deleteCard={deleteCard}
        />
      )
    }, [])
    return (
      <>
        <div style={style}>{userLinks.map((card, i) => renderCard(card, i))}</div>
      </>
    )
}
