import React, { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import ButtonAppBar from "../components/navBar";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ShopForm from "../components/shopForm";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports.js";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmbedCodeModal from "../components/embedCodeModal.js";
import WelcomeNewUserModal from "../components/welcomeNewUserModal.js";
import Popover from "@mui/material/Popover";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useNavigate } from "react-router-dom";

Amplify.configure(awsconfig);

const FitPrintProducts = () => {
  let navigate = useNavigate();

  function navigateToCreate(event) {
    navigate('/createNewProduct');
  }

  return (
    <>
    <ButtonAppBar />
      <CssBaseline />
      <Container
        maxWidth="lg"
        sx={{
          minWidth: "1155px",
          padding: "10px",
        }}
      >
      <Button
        variant="contained"
        onClick={navigateToCreate}
        sx={{ width: "150px", float:"right", marginRight: "-10px"}}
      >
        ADD NEW
      </Button>{" "}
      <Typography
        align="left"
        variant="h5"
        gutterBottom
        component="div"
      >
        {" "}
        <Box
          sx={{
            fontWeight: "bold",
            m: 1,
          }}
        >
          My Products
        </Box>{" "}
      </Typography>
      </Container>
  </>
);
};
export default FitPrintProducts;
