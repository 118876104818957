import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import useStore from "../store.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid #EBEBEB",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const AddCustomSizeModal = ({ open, onClose }) => {

  const handleCloseButtonClicked = () => {
    setCustomSize('')
    onClose();
  };


  const [customSize, setCustomSize] = React.useState('');
  const localCustomFitPrintProduct = useStore((state) => state.localCustomFitPrintProduct);
  const updateLocalProduct = useStore((state) => state.setLocalCustomFitPrintProduct);
  const sizes = useStore((state) => state.localCustomFitPrintProduct.options.find(option => option.type === 'size').values);


  function handleCustomSizeChange(event) {
    setCustomSize(event.target.value);
  }

  function handleSaveButtonClicked() {
    let updatedLocalProduct = localCustomFitPrintProduct
    const sizeIndex = updatedLocalProduct.options.findIndex(option => option.type === 'size')
    updatedLocalProduct.options[sizeIndex].values = [
      ...sizes, {'id': Math.floor(Date.now() + Math.random()), 'title': customSize}
    ]
    updateLocalProduct(updatedLocalProduct)
    setCustomSize('')
    onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create a custom size
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Here you can create a custom size to be used in your variants for this product
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            id="CustomSizeText"
            label="Custom Size Option"

            value={customSize}
            onChange={handleCustomSizeChange}
          />{" "}
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "20px" }}
          >
            <Button variant="contained" onClick={handleCloseButtonClicked} sx ={{ marginRight: '10px'}}>
              Close
            </Button>
            <Button variant="contained" onClick={handleSaveButtonClicked} sx ={{ marginLeft: '10px'}}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AddCustomSizeModal;
