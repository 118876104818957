import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid #EBEBEB",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const CollectSalesTaxInfoModal = ({ open, onClose }) => {

  const handleCloseButtonClicked = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Setup Stripe Sales Tax
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            If you would like to collect sales tax, you need to <Link
              href="https://dashboard.stripe.com/tax-rates"
              underline="hover"
            >
              {"set up a tax rate within Stripe."}
            </Link> Enable the switch to the left, and create a new tax rate and copy its TAX RATE ID and paste it
            into the provided text field. As always you can reach out to us at{" "}
            <Link href="mailto:admin@fitprint.io" underline="hover">
              {"admin@fitprint.io"}
            </Link>{" "}
            with any questions.
          </Typography>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "20px" }}
          >
            <Button variant="contained" onClick={handleCloseButtonClicked}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CollectSalesTaxInfoModal;
