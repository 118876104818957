import * as React from "react";
import Box from "@mui/material/Box";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CircularProgress from "@mui/material/CircularProgress";
import Badge from "@mui/material/Badge";

const ConnectionStatusBadge = ({ isConnected, isLoading }) => {
  if (isLoading) {
    return (
      <>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          textAlign="center"
        >
          <CircularProgress size="20px" sx={{marginLeft:"-10px"}}/>
        </Box>
      </>
    );
  } else {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        textAlign="center"
      >
        <Badge
          badgeContent=""
          color={isConnected ? "success" : "error"}
        ></Badge>
      </Box>
    );
  }
};

export default ConnectionStatusBadge;
