import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid #EBEBEB",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const PassFitPrintFeesModal = ({ open, infoOnly, onClose }) => {
  const handleCloseButtonClicked = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {infoOnly ? 'Pass FitPrint Fees To Customer' : 'Are you sure!?'}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            By default, we pass <Link
              href="https://fitprint.io"
              underline="hover"
            >
              {"our 4.5% + $0.50 per transaction fee"}
            </Link> on to the
            end customer to ensure you are not losing money. If you understand you
            are responsible for these fees and price your items accordingly,
            you can disable this switch and they will NOT be passed on to your
            customer. You can find more information {" "}
            <Link
              href="https://www.youtube.com/channel/UCR4ugUMDzcCU4YbnWASh6lw"
              underline="hover"
            >
              {"here"}
            </Link>{" "}
            or reach out to us at{" "}
            <Link href="mailto:admin@fitprint.io" underline="hover">
              {"admin@fitprint.io"}
            </Link>{" "}
            with any questions.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            We also offer a monthly subscription where these fees can be waived
            completely.  If you are interested in learning more please contact us at <Link href="mailto:admin@fitprint.io" underline="hover">
              {"admin@fitprint.io"}
            </Link>{" "}
            </Typography>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "20px" }}
          >
            <Button variant="contained" onClick={handleCloseButtonClicked}>
              {infoOnly ? 'close' : 'YES. I understand how to price my Products'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PassFitPrintFeesModal;
