import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #EBEBEB",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const WelcomeNewUserModal = ({ open, onClose }) => {

  const handleCloseButtonClicked = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Welcome to FitPrint
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This is where you can customize your own store.
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            The preview pane on the right is actually a live view of your shop! Change some colors and click save to see your updates!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You can visit your store in any browser or application by using the shop url above the preview.
            You can also embed this store into your existing website by tapping the EMBED CODE button{"\n"}{"\n"}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            We have started you out by connecting your shop to our FitPrint Printify store. Toggle the Connect to Printify switch to ON to connect your own Printify Store.{"\n"}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              You will need to Connect your shop to Stripe before you can accept payments. Simply toggle the Connect to Stripe switch to be guided through the process.
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You can check out some of our{" "}
            <Link
              href="https://www.youtube.com/channel/UCR4ugUMDzcCU4YbnWASh6lw"
              underline="hover"
            >
              {"tutorials on Youtube"}
            </Link>{" "}
            or reach out to us at{" "}
            <Link href="mailto:admin@fitprint.io" underline="hover">
              {"admin@fitprint.io"}
            </Link>{" "}
            if you need any help.
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            By using this software you agree to our
            <Link
              href="https://go.fitprint.io/terms-of-service.html"
              underline="hover"
            >
              {" Terms of Service"}
            </Link>{" "}
            and accept our
            <Link
              href="https://go.fitprint.io/privacy-policy.html"
              underline="hover"
            >
              {" Privacy Policy"}
            </Link>{" "}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You can access this help menu at any time by tapping the ? in the upper right corner.
            </Typography>
          </Typography>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "20px" }}
          >
            <Button variant="contained" onClick={handleCloseButtonClicked}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default WelcomeNewUserModal;
