import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Popover from "@mui/material/Popover";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid #EBEBEB",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const EmbedCodeModal = ({ open, onClose }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const copyPopOverOpen = Boolean(anchorEl);
  const copyPopOverId = copyPopOverOpen ? "simple-popover" : undefined;
  const { user } = useAuthenticator((context) => [context.user]);
  const handleCloseButtonClicked = () => {
    onClose();
  };

  const handleCopyUrlClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    navigator.clipboard.writeText(
      `<div style="max-width: 1040px; margin: 0 auto;">
                <div>
                <a class="anchor" id="fitPrintAnchor" style="padding-top: 0px; margin-top: 0px;"></a>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.min.js"></script>
                <script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
                <script type="text/javascript">
                  iFrameResize(
                  {
                    log: false,
                    checkOrigin: false,
                    heightCalculationMethod: 'grow',
                    autoResize: false,
                    onMessage: function(messageData) {
                      var jsonData = JSON.parse(messageData.message);
                      if (jsonData.action == 'navigateTo') {
                        window.onbeforeunload = null;
                        window.parent.location.href= jsonData.data;
                      }
                      if (jsonData.action == 'scrollTo') {
                        setTimeout(function () {
                          document.getElementById('fitPrintAnchor').scrollIntoView({behavior: 'smooth', block: 'center'});
                        },200);
                      }
                },
                    onInit: function(iframe)
                    {
                      $(document).ready(function()
                      {
          iframe.iFrameResizer = {
                        $(window).on('scroll', () =>
                        {
                          const scrollFromTop = $(document).scrollTop();
                          var fitPrintIframe = $("#fitPrintIframe");
                          var offset = fitPrintIframe.offset();
                          if (scrollFromTop > offset.top + 10)
                          {
                            iframe.iFrameResizer.sendMessage(scrollFromTop - (offset.top - 10));
                          }
                        });
                      });
                    }
                  }, '#fitPrintIframe');
                </script>
                <br>
                <iframe id="fitPrintIframe" frameborder="0" scrolling="no" src="https://shop.fitprint.io/v2.html?shop=${user.attributes.sub}" width="100%" onload="iFrameResize({ log: false, checkOrigin: false, heightCalculationMethod: 'grow', autoResize: false, onMessage: function(messageData) {var jsonData = JSON.parse(messageData.message);if (jsonData.action == 'navigateTo') {window.onbeforeunload = null;window.parent.location.href= jsonData.data;}if (jsonData.action == 'scrollTo') {setTimeout(function () {document.getElementById('fitPrintAnchor').scrollIntoView({behavior: 'smooth', block: 'center'});},200);}},onInit: function(iframe) { $(document).ready(function () { $(window).on('scroll', () => { const scrollFromTop = $(document).scrollTop(); var fitPrintIframe = $('#fitPrintIframe'); var offset = fitPrintIframe.offset(); if (scrollFromTop > offset.top + 10) { iframe.iFrameResizer.sendMessage(scrollFromTop - (offset.top - 10)); }if (scrollFromTop == 0 ) { iframe.iFrameResizer.sendMessage(scrollFromTop); }  }); }); }}, '#fitPrintIframe');"></iframe>
                <style>
                  iframe {
                    width: 1px; min-width: 100%;
                  }
                </style>
                </div>
              </div>`
    );
    setTimeout(function () {
      setAnchorEl(null);
    }, 1500);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Embed In A Website
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Simply copy the code below and paste it into any HTML website. You
            can check out some of our{" "}
            <Link
              href="https://www.youtube.com/channel/UCR4ugUMDzcCU4YbnWASh6lw"
              underline="hover"
            >
              {"tutorials on Youtube"}
            </Link>{" "}
            or reach out to us at{" "}
            <Link href="mailto:admin@fitprint.io" underline="hover">
              {"admin@fitprint.io"}
            </Link>{" "}
            if you need help getting your store embedded into any website.
          </Typography>
          <Box
            alignItems="right"
            display="flex"
            justifyContent="right"
            textAlign="right"
            sx={{ backgroundColor: "#EBEBEB", marginBottom:"-25px"}}
          >
          <IconButton
            aria-label="copy"
            size="small"
            onClick={handleCopyUrlClicked}
          >
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
          <Popover
            id={copyPopOverId}
            open={copyPopOverOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}

          >
            <Typography sx={{p: 2 }}>Copied!</Typography>
          </Popover>
          </Box>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="left"
            sx={{ backgroundColor: "#EBEBEB", padding: "10px", height:"375px" }}
          >
            <Typography variant="caption" sx ={{ overflow: "scroll", height:"350px", marginTop:"20px"}}>
              {`<div style="max-width: 1040px; margin: 0 auto;">
                      	<div>
                        <a class="anchor" id="fitPrintAnchor" style="padding-top: 0px; margin-top: 0px;"></a>
                      	<script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.min.js"></script>
                      	<script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
                      	<script type="text/javascript">
                      		iFrameResize(
                      		{
                      			log: false,
                      			checkOrigin: false,
                      			heightCalculationMethod: 'grow',
                      			autoResize: false,
                            onMessage: function(messageData) {
                              var jsonData = JSON.parse(messageData.message);
                              if (jsonData.action == 'navigateTo') {
                                window.onbeforeunload = null;
                                window.parent.location.href= jsonData.data;
                              }
                              if (jsonData.action == 'scrollTo') {
                                setTimeout(function () {
                                  document.getElementById('fitPrintAnchor').scrollIntoView({behavior: 'smooth', block: 'center'});
                                },200);
                              }
                				},
                      			onInit: function(iframe)
                      			{
                      				$(document).ready(function()
                      				{
                  iframe.iFrameResizer = {
                      					$(window).on('scroll', () =>
                      					{
                      						const scrollFromTop = $(document).scrollTop();
                      						var fitPrintIframe = $("#fitPrintIframe");
                      						var offset = fitPrintIframe.offset();
                      						if (scrollFromTop > offset.top + 10)
                      						{
                      							iframe.iFrameResizer.sendMessage(scrollFromTop - (offset.top - 10));
                      						}
                      					});
                      				});
                      			}
                      		}, '#fitPrintIframe');
                      	</script>
                      	<br>
                      	<iframe id="fitPrintIframe" frameborder="0" scrolling="no" src="https://shop.fitprint.io/v2.html?shop=${user.attributes.sub}" width="100%" onload="iFrameResize({ log: false, checkOrigin: false, heightCalculationMethod: 'grow', autoResize: false, onMessage: function(messageData) {var jsonData = JSON.parse(messageData.message);if (jsonData.action == 'navigateTo') {window.onbeforeunload = null;window.parent.location.href= jsonData.data;}if (jsonData.action == 'scrollTo') {setTimeout(function () {document.getElementById('fitPrintAnchor').scrollIntoView({behavior: 'smooth', block: 'center'});},200);}},onInit: function(iframe) { $(document).ready(function () { $(window).on('scroll', () => { const scrollFromTop = $(document).scrollTop(); var fitPrintIframe = $('#fitPrintIframe'); var offset = fitPrintIframe.offset(); if (scrollFromTop > offset.top + 10) { iframe.iFrameResizer.sendMessage(scrollFromTop - (offset.top - 10)); }if (scrollFromTop == 0 ) { iframe.iFrameResizer.sendMessage(scrollFromTop); }  }); }); }}, '#fitPrintIframe');"></iframe>
                      	<style>
                      		iframe {
                      			width: 1px; min-width: 100%;
                      		}
                      	</style>
                      	</div>
                      </div>`}
            </Typography>
          </Box>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "20px" }}
          >
            <Button variant="contained" onClick={handleCloseButtonClicked}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default EmbedCodeModal;
