import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import useStore from "../store.js";
import ColorPicker from "../components/colorPicker";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid #EBEBEB",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  alignItems: "center",
  marginTop: "6px",
  marginLeft: "10px",
  color: theme.palette.text.secondary,
}));

const AddCustomColorModal = ({ open, onClose }) => {

  const handleCloseButtonClicked = () => {
    setCustomColor('')
    onClose();
  };


  const [customColor, setCustomColor] = React.useState('');
  const [hexValue, setHexValue] = React.useState('');
  const localCustomFitPrintProduct = useStore((state) => state.localCustomFitPrintProduct);
  const updateLocalProduct = useStore((state) => state.setLocalCustomFitPrintProduct);
  const colors = useStore((state) => state.localCustomFitPrintProduct.options.find(option => option.type === 'color').values);


  function handleCustomColorChange(event) {
    setCustomColor(event.target.value);
  }

  const handleHexValueChange = (color, event) => {
    console.log(color)
    setHexValue(color);
  }

  function handleSaveButtonClicked() {
    let updatedLocalProduct = localCustomFitPrintProduct
    const sizeIndex = updatedLocalProduct.options.findIndex(option => option.type === 'color')
    updatedLocalProduct.options[sizeIndex].values = [
      ...colors, {'id': Math.floor(Date.now() + Math.random()), 'title': customColor, 'colors':[hexValue]}
    ]
    updateLocalProduct(updatedLocalProduct)
    setCustomColor('')
    onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create a custom color
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Here you can create a custom color to be used in your variants for this product
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            textAlign="center"
          >
          <TextField
            fullWidth
            margin="normal"
            id="CustomColorText"
            label="Custom Color Option"

            value={customColor}
            onChange={handleCustomColorChange}
          />{" "}
          <Item>
          <ColorPicker
            parentCallback={handleHexValueChange}
            initialColor={hexValue}
          />{" "}
          </Item>
          </Stack>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "20px" }}
          >
            <Button variant="contained" onClick={handleCloseButtonClicked} sx ={{ marginRight: '10px'}}>
              Close
            </Button>
            <Button variant="contained" onClick={handleSaveButtonClicked} sx ={{ marginLeft: '10px'}}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AddCustomColorModal;
