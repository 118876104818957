import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Example from './example.js'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useStore from '../store.js';
import Grid from "@mui/material/Grid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #EBEBEB",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const  CreateUserLinksModal = ({ open, onClose, onSave }) => {

  const [currentTitle, setCurrentTitle] = React.useState('');
  const addToArray = useStore((state) => state.addUserLink);

  const handleCloseButtonClicked = () => {
    onClose();
  };

  const handleSaveButtonClicked = () => {
    onSave();
  };

  function handleTitleChange(event) {
    setCurrentTitle(event.target.value);
  }

  const [currentURL, setCurrentURL] = React.useState('');

  function handleURLChange(event) {
    setCurrentURL(event.target.value);
  }

  const handleAddButtonClicked = () => {
    addToArray({'title': currentTitle, 'url': currentURL})
    setCurrentTitle("");
    setCurrentURL("");
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create Links For Your Shop
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This is where you can customize links that appear in the side menu of your shop. Use these links to redirect back to your main site, affiliate links, or some of your social media sites.
          </Typography>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { marginTop: "10px", marginBottom: "10px" },
            }}
            noValidate
            autoComplete="off"
          >
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center">

            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              sx={{ width: "200px" }}
              value={currentTitle}
              onChange={handleTitleChange}/>
            <TextField
              id="outlined-basic"
              label="URL"
              variant="outlined"
              sx={{ width: "350px" }}
              value={currentURL}
              onChange={handleURLChange}/>
            <IconButton
              aria-label="add"
              color="primary"
              onClick={handleAddButtonClicked}
              >
              <AddCircleOutlineIcon />
            </IconButton>
          </Stack>
          </Box>
          <DndProvider backend={HTML5Backend}>
            <Example />
          </DndProvider>

          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "20px" }}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
              <Button variant="contained" color="error" onClick={handleCloseButtonClicked}>
                Close
              </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" color="success" onClick={handleSaveButtonClicked}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default  CreateUserLinksModal;
