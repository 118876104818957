"use strict";

import React, { useState, useEffect, useCallback } from "react";
import reactCSS from "reactcss";
import { ChromePicker } from "react-color";
import rgbHex from "rgb-hex";

const ColorPicker = ({ parentCallback, initialColor}) => {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [color, setColor] = React.useState('');

  function handleClick(){
    setDisplayColorPicker(!displayColorPicker)
  }

  function handleClose() {
    setDisplayColorPicker(false)
  }

  function handleChange(color) {
    setColor("#" + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a))
    // setColor(color.hex)
  }

  function handleChangeComplete(color, event) {
    event.preventDefault();
   console.log(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a)
   // setColor(color.hex);
   setColor("#" + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a))
   console.log('setColor' + color.hex);
   console.log(parentCallback);
   parentCallback("#" + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a), event);
 };

    const styles = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: color,
        },
        swatch: {
          padding: "5px",
          background: color,
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });


    useEffect(() => {
      setColor(initialColor)

    }, [])
    return (
      <React.Fragment>
      <div>
        <div style={styles.swatch} onClick={handleClick}>
          <div style={styles.color} />
        </div>
        {displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={handleClose} />
            <ChromePicker
              color={color}
              onChange={handleChange}
              onChangeComplete={ (color, event) => handleChangeComplete(color, event) }
            />
          </div>
        ) : null}
      </div>
      </React.Fragment>
    );
  }


export default React.memo(ColorPicker);
