import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Amplify, { API } from "aws-amplify";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const ConnectToStripeModal = ({ open, onClose }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);

  const handleConnectButtonClicked = () => {
    setIsLoaded(false)
    const apiName = "FitPrintAPI";
    const path =  `/private/connectStripeAccount/${user.attributes.sub}`;
    API.get(apiName, path)
      .then((response) => {
        setIsLoaded(true)
        console.log(response.url);
        window.location.href = response.url;
      })
      .catch((error) => {
        setIsLoaded(true)
        setError(error)
        alert(error);
        onClose();
      });
  };

  const handleCloseButtonClicked = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Connect To Stripe
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          We are proud to partner with Stripe for secure online payment processing.
          </Typography>
          <Typography sx={{ mt: 2 }}>
          If you already have a stripe account {" "}
          </Typography>
          <Typography sx={{ mt: 2 }}>
          <Link href="https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_KEuhovjBvrQnRpGd5fhrdhKBLfPwpFEB&scope=read_write&redirect_uri=https://shop.fitprint.io/stripeSuccessRedirect.html" underline="hover">
           CONNECT MY EXISTING STRIPE ACCOUNT
          </Link>{" "}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Otherwise, simply click the CREATE NEW STRIPE ACCOUNT button below to create and link a new Stripe account.
          </Typography>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "20px" }}
          >
            <Button color= "warning" variant="contained" onClick={handleCloseButtonClicked} sx={{ marginRight: "10px" }} >
              Cancel
            </Button>
            <LoadingButton variant="contained" onClick={handleConnectButtonClicked} sx={{ marginLeft: "10px" }} loading={!isLoaded}>
              CREATE NEW STRIPE ACCOUNT
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ConnectToStripeModal;
