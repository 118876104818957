import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";
import { useAuthenticator } from "@aws-amplify/ui-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const ConnectToPrintifyModal = ({ open, onClose }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const handleConnectButtonClicked = () => {
    window.location.href = `https://printify.com/app/authorize?app_id=61fd0d92ee4a4de07cf4aeed&accept_url=https://shop.fitprint.io/printifySuccessRedirect.html&decline_url=https://shop.fitprint.io/?shop=${user.attributes.sub}/printifyConnectionSuccess`
    onClose();
  };

  const handleCloseButtonClicked = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Connect To Printify
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          The following steps will immediately pull in all your Prinitfy products and make them available in your FitPrint shop:
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            1. <Link
             target="_blank"
              href="https://printify.com/?ref=bartpowers&utm_source=affiliate&utm_medium=referral&utm_campaign=printify&amcc_channel=affiliate&amcc_campaign=printify"
              underline="hover"
            >Create or login to your Printify Account</Link>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            2. <Link
            target="_blank"
            href="https://www.youtube.com/watch?v=n9HXLnVjaQU"
            underline="hover">Add at least one product to your Printify Store.
            </Link>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          If you have completed the 2 steps above, simply click the CONNECT button below.
          </Typography>

          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "20px" }}
          >
            <Button color= "warning" variant="contained" onClick={handleCloseButtonClicked} sx={{ marginRight: "10px" }} >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleConnectButtonClicked} sx={{ marginLeft: "10px" }}>
              Connect
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ConnectToPrintifyModal;
