import { useCallback } from "react";
import { DraggableImage } from "./draggableImage.js";
import useStore from "../store.js";
import ImageList from '@mui/material/ImageList';
import Grid from "@mui/material/Grid";

export const DraggableImageContainer = () => {
    const productImages = useStore((state) => state.productImages);
    const removeFromArray = useStore((state) => state.removeProductImage);
    const moveInArray = useStore((state) => state.moveProductImage);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
      moveInArray(dragIndex, hoverIndex);
    }, []);

    const deleteCard = useCallback((index) => {
      removeFromArray(index);
    }, []);

    const renderCard = useCallback((imageData, index) => {
      return (
        <Grid item xs={3}>
        <DraggableImage
          id={imageData.id}
          src={imageData.src}
          isMain={imageData.isMain}
          key={imageData.id}
          index={index}
          moveCard={moveCard}
          deleteCard={deleteCard}
        />
        </Grid>
      );
    }, []);
    return (
      <>
      <Grid container
            spacing={2}
            sx={{
              maxWidth: '1040px',
            }}>

            {productImages.map((imageData, i) => renderCard(imageData, i))}

        </Grid>
      </>
    );
};

export default DraggableImageContainer;
