import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import {useAuthenticator} from '@aws-amplify/ui-react';
import { CardMedia } from '@mui/material';

export default function ButtonAppBar() {
  const {user, signOut} = useAuthenticator((context) => [context.user]);


  return (<Box sx={{
      flexGrow: 1
    }}>
    <AppBar position="static" style={{ background: '#ffffff', color: 'black' }}>
      <Toolbar>
      <CardMedia
style={{
  width: "150px",
  maxHeight: "auto"
}}
component="img"
image="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1635799168136x267145445046834700%2FUntitled.png?w=256&h=51&auto=compress&fit=crop&dpr=1"
/>

        <Typography variant="h6" component="div" sx={{
            flexGrow: 1
          }}>
        </Typography>
        <Button variant="contained" onClick={signOut}>Logout</Button>
      </Toolbar>
    </AppBar>
  </Box>);
}
