import React from 'react';
import Webpages from './webpages';
import { Amplify, Auth } from 'aws-amplify';
import {
  Authenticator,
  AmplifyProvider,
  Theme,
  Flex,
  Grid,
  Image,
  useTheme,
  View
} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';

Auth.configure(awsExports);

function App() {
  const { tokens } = useTheme();
  const theme: Theme = {
    name: 'Auth Example Theme',
    overrides: [
   {
     tokens: {
       colors: {
         blue: { value: '#009dff' },
       },
     },
   },

 ],
    tokens: {
      colors: {
        background: {
          primary: {
            value: tokens.colors.white.value,
          },
          secondary: {
            value: tokens.colors.neutral['20'].value,
          },
        },
        font: {
          interactive: {
            value: '#009dff',
          },
        },
        brand: {
          primary: {
            '10': {value: '#009dff'},
            '20': {value: '#009dff'},
            '30': {value: '#009dff'},
            '40': {value: '#009dff'},
            '50': {value: '#009dff'},
            '60': {value: '#009dff'},
            '70': {value: '#009dff'},
            '80': {value: '#009dff'},
            '90': {value: '#009dff'},
            '100': {value: '#009dff'},
          },
        },
      },
      components: {
        button: {
          _focus: {
            color: {
              value: '#ffffff',
            },
          },
          _hover: {
            color: {
              value: '#ffffff',
            },
          },
        },
        tabs: {
          item: {
            _focus: {
              color: {
                value: '#009dff',
              },
            },
            _hover: {
              color: {
                value: '#009dff',
              },
            },
            _active: {
              color: {
                value: tokens.colors.black.value,
              },
            },
          },
        },
      },
    },
  };

  const components = {
  Header() {
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="FitPrint logo"
          src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1635799168136x267145445046834700%2FUntitled.png?w=256&h=51&auto=compress&fit=crop&dpr=1"
        />
      </View>
    );
  },
}

  return (
    <AmplifyProvider theme={theme}>
    <Authenticator components={components} theme={theme}>
      {({ signOut, user }) => (
        <div>
          <Webpages />
        </div>
      )}
    </Authenticator>
    </AmplifyProvider>
  );
}

export default App;
