import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Example from './example.js'
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useStore from '../store.js';
import CategoryContainer from './categoriesContainer.js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #EBEBEB",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const CreateCategoryModal = () => {

  const [currentTitle, setCurrentTitle] = React.useState('');
  const addToArray = useStore((state) => state.addCategory);

  function handleTitleChange(event) {
    setCurrentTitle(event.target.value);
  }

  const handleAddButtonClicked = () => {
    addToArray({'title': currentTitle })
    setCurrentTitle("");
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
    <IconButton
      aria-label="add"
      color="primary"
      onClick={handleOpen}
    >
      <AddCircleOutlineIcon />
    </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Manage Your Link Categories
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            These categories will help sort links on your custom page.
          </Typography>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { marginTop: "10px", marginBottom: "10px" },
            }}
            noValidate
            autoComplete="off"
          >
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center">

            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              sx={{ width: "200px" }}
              value={currentTitle}
              onChange={handleTitleChange}/>
            <IconButton
              aria-label="add"
              color="primary"
              onClick={handleAddButtonClicked}
              >
              <AddCircleOutlineIcon />
            </IconButton>
          </Stack>
          </Box>
          <DndProvider backend={HTML5Backend} >
              <CategoryContainer />
          </DndProvider>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "20px" }}
          >
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateCategoryModal;
