import create from "zustand";
import update from 'immutability-helper'
import uuid from 'react-native-uuid';


const useStore = create((set) => ({
  userLinks: [
    {title: 'FitPrint', url: 'https://go.fitprint.io/', id: 1}
  ],

  setUserLinksArray: (links) => set((state) => ({
    userLinks: links
  })),

  addUserLink: (link) => set((state) => ({
    userLinks: [
      ...state.userLinks, {title: link.title, url: link.url, id: uuid.v4()}
    ]
  })),

  removeUserLink: (id) => set((state) => ({
    userLinks: state.userLinks.filter((link) => link.id !== id)
  })),

  moveUserLink: (dragIndex, hoverIndex) => set((state) => ({
    userLinks: update(state.userLinks, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, state.userLinks[dragIndex]],
      ],
    })
  })),

  categories: [],

  setCategoriesArray: (categories) => set((state) => ({
    categories: categories
  })),

  addCategory: (category) => set((state) => ({
    categories: [
      ...state.categories, {id: uuid.v4(), title: category.title}
    ]
  })),

  removeCategory: (id) => set((state) => ({
    categories: state.categories.filter((category) => category.id !== id)
  })),

  moveCategory: (dragIndex, hoverIndex) => set((state) => ({
    categories: update(state.categories, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, state.categories[dragIndex]],
      ],
    })
  })),

  affiliateLinks: [],

  setAffiliateLinksArray: (links) => set((state) => ({
    affiliateLinks: links
  })),

  addAffiliateLink: (link) => set((state) => ({
    affiliateLinks: [
      ...state.affiliateLinks, {id: uuid.v4(), category: link.category,
                                price: link.price, title: link.title,
                                subTitle: link.subTitle,
                                description: link.description,
                                imageURL: link.imageURL,
                                affiliateLink: link.affiliateLink,
                                buttonText: link.buttonText}
    ]
  })),

  removeAffiliateLink: (id) => set((state) => ({
    affiliateLinks: state.affiliateLinks.filter((link) => link.id !== id)
  })),

  moveAffiliateLink: (dragIndex, hoverIndex) => set((state) => ({
    affiliateLinks: update(state.affiliateLinks, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, state.affiliateLinks[dragIndex]],
      ],
    })
  })),

  fitPrintUser: null,

  setFitPrintUser: (user) => set((state) => ({
    fitPrintUser: user
  })),

  productImages: [
    {
      src: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      isMain: true,
      id: 1
    },
    {
      src: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      isMain: false,
      id: 2
    },
    {
      src: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      isMain: false,
      id: 3
    },
    {
      src: 'https://raw.githubusercontent.com/IQAndreas/sample-images/gh-pages/100-100-color/00.jpg',
      isMain: true,
      id: 4
    },
    {
      src: 'https://raw.githubusercontent.com/IQAndreas/sample-images/gh-pages/100-100-color/01.jpg',
      isMain: false,
      id: 5
    },
    {
      src: 'https://raw.githubusercontent.com/IQAndreas/sample-images/gh-pages/100-100-color/02.jpg',
      isMain: false,
      id: 6
    }
  ],

  setProductImages: (images) => set((state) => ({
    productImages: images
  })),

  addProductImage: (imageData) => set((state) => ({
    productImages: [
      ...state.productImages, {image: imageData.image, isMain: imageData.isMain, id: uuid.v4()}
    ]
  })),

  removeProductImage: (id) => set((state) => ({
    productImages: state.productImages.filter((image) => image.id !== id)
  })),

  moveProductImage: (dragIndex, hoverIndex) => set((state) => ({
    productImages: update(state.productImages, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, state.productImages[dragIndex]],
      ],
    })
  })),

  localCustomFitPrintProduct: {
    id: "6283ed03dd600f751357a579",
    title: "Koozie",
    description: "Made to keep your drinks crispy-fresh and cool-looking, these can insulators are the best companion for nature trips and parties. They're super lightweight and will fit in any backpack. Edge to edge printing allows full customization &ndash; decorate it with fun illustrations or personalized name tags to avoid grabbing the wrong beverage.</p>\n<p>.: White polyester exterior and black soft foam liner interior. Available in Two Sizes: ",
    options: [
        {
            name: "Size",
            type: "size",
            values: [
                {
                    id: 2,
                    title: "S"
                },
                {
                    id: 3,
                    title: "M"
                },
                {
                    id: 4,
                    title: "L"
                }
            ]
        },
        {
            name: "Colors",
            type: "color",
            values: [
                {
                    id: 521,
                    title: "White",
                    colors: [
                        "#F9F9F9"
                    ]
                },
                {
                    id: 418,
                    title: "Black",
                    colors: [
                        "#050505"
                    ]
                }
            ]
        }
    ],
    variants: [
        {
            id: 78460,
            sku: "29534665494759494278",
            price: 500,
            title: "Regular Can",
            is_enabled: true,
            is_default: true,
            is_available: false,
            options: [
                2, 521
            ],

        },
        {
            id: 78461,
            sku: "24485196531251313831",
            price: 500,
            title: "Slim Can",
            is_enabled: true,
            is_default: false,
            is_available: true,
            options: [
                2, 418
            ],
        }
    ]


  },


  setLocalCustomFitPrintProduct: (product) => set((state) => ({
    localCustomFitPrintProduct: product
  })),

}));

export default useStore;
