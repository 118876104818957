import React, { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import ButtonAppBar from "../components/navBar";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ShopForm from "../components/shopForm";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports.js";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmbedCodeModal from "../components/embedCodeModal.js";
import WelcomeNewUserModal from "../components/welcomeNewUserModal.js";
import Popover from "@mui/material/Popover";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useStore from '../store.js';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import PreviewIcon from '@mui/icons-material/Preview';

Amplify.configure(awsconfig);

const Home = () => {
  const [openEmbedCodeModal, setOpenEmbedCodeModal] = useState(false);
  const handleOpenEmbedCodeModal = () => setOpenEmbedCodeModal(true);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const handleOpenHelpModal = () => setOpenHelpModal(true);
  const setUserLinksArray = useStore((state) => state.setUserLinksArray);
  const setAffiliateLinksArray = useStore((state) => state.setAffiliateLinksArray);
  const setAffiliateLinkCategories = useStore((state) => state.setCategoriesArray);
  const addToArray = useStore((state) => state.addUserLink);
  const setGlobalFitPrintUser = useStore((state) => state.setFitPrintUser);
  const [anchorEl, setAnchorEl] = useState(null);

  const copyPopOverOpen = Boolean(anchorEl);
  const copyPopOverId = copyPopOverOpen ? "simple-popover" : undefined;

  const handleCopyUrlClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (previewPreference === "products") {
      navigator.clipboard.writeText(
        `https://shop.fitprint.io/printify-products-v2.html?shop=${user.attributes.sub}`
      );
    }
    if (previewPreference === "links") {
      navigator.clipboard.writeText(
        `https://shop.fitprint.io/affiliate-products.html?shop=${user.attributes.sub}`
      );
    }

    setTimeout(function () {
      setAnchorEl(null);
    }, 1500);
  };

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [fitPrintUser, setFitPrintUser] = useState(null);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [previewPreference, setPreviewPreference] = useState(() => {
    const saved = localStorage.getItem("previewPreference");
    return saved || "products";
  });
  const [iframeSrc, setIframeSrc] = useState(() => {
    var page = "printify-products-v2"
    if (previewPreference === "products") {
      page = "printify-products-v2";
    }
    if (previewPreference === "links") {
      page = "affiliate-products-redirect";
    }
    return `https://shop.fitprint.io/${page}.html?shop=${user.attributes.sub}&ts=${Date.now()}`
  });

  const pageSrc = useState(() => {
    var page = "printify-products-v2"
    if (previewPreference === "products") {
      page = "printify-products-v2";
    }
    if (previewPreference === "links") {
      page = "affiliate-products";
    }
    return `https://shop.fitprint.io/${page}.html?shop=${user.attributes.sub}&ts=${Date.now()}`
  });

  function setPreviewUrl(event) {
    console.log(event.target.value);
    setPreviewPreference(event.target.value);
    localStorage.setItem("previewPreference", event.target.value);
    var page = "";
    if (event.target.value === "products") {
      page = "printify-products-v2";
    }
    if (event.target.value === "links") {
      page = "affiliate-products-redirect";
    }
    let test = `https://shop.fitprint.io/${page}.html?shop=${user.attributes.sub}&ts=${Date.now()}`;

    setIframeSrc(
      `https://shop.fitprint.io/${page}.html?shop=${user.attributes.sub}&ts=${Date.now()}`
    );
    document.getElementById('FitPrintIframe').src = test;
  }

  const handleSaveButtonClicked = () => {
    var page = "printify-products-v2"
    if (previewPreference === "products") {
      page = "printify-products-v2";
    }
    if (previewPreference === "links") {
      page = "affiliate-products-redirect";
    }
    console.log(previewPreference);
    let test = `https://shop.fitprint.io/${page}.html?shop=${user.attributes.sub}&ts=${Date.now()}`;

    setIframeSrc(
      `https://shop.fitprint.io/${page}.html?shop=${user.attributes.sub}&ts=${Date.now()}`
    );
    console.log(test);
    document.getElementById('FitPrintIframe').src = test;
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F2F2F2",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const apiName = "FitPrintAPI";

  useEffect(() => {
    API.get(apiName, `/private/object/SHOP.${user.attributes.sub}/ACCOUNT.INFO`)
      .then((response) => {
        var responseLinks = response.links;
        if (responseLinks === undefined || responseLinks.length === 0) {
        } else {
          setUserLinksArray(responseLinks);
        }
        if (response.affiliateLinkCategories === undefined || response.affiliateLinkCategories.length === 0) {

        } else {
          console.log(response.affiliateLinkCategories);
          setAffiliateLinkCategories(response.affiliateLinkCategories);
        }
        if (response.affiliateLinks === undefined || response.affiliateLinks.length === 0) {

        } else {
          console.log(response.affiliateLinks);
          setAffiliateLinksArray(response.affiliateLinks);
        }

        if (Object.keys(response).length === 0) {
          console.log("here");
          console.log(user.attributes);
          const postRequestBody = {
            userName: user.username,
            email: user.attributes.email
          }
          const myInit = {
            body: postRequestBody
          };
          API.post(
            apiName,
            `/private/createAccount/${user.attributes.sub}`,
            myInit
          )
            .then((response) => {
              console.log(response);
              setFitPrintUser(response.fitPrintUser);
              setGlobalFitPrintUser(response.fitPrintUser);
              var newUserLinks = response.fitPrintUser.links;
              if (newUserLinks === undefined || newUserLinks.length === 0) {
              } else {
                setUserLinksArray(newUserLinks);
              }
              setIsLoaded(true);
              setOpenHelpModal(true);
            })
            .catch((error) => {
              console.log(error.response);
              alert(error.response);
            });
        } else {
          setIsLoaded(true);
          setFitPrintUser(response);
          setGlobalFitPrintUser(response)
        }
      })
      .catch((error) => {
        setIsLoaded(true);
        setError(error);
        console.log(error);
        console.log(user.attributes);
      });
  }, []);
  if (error) {
    return <div>Error</div>;
  } else if (!isLoaded || !fitPrintUser) {
    return (
      <>
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      sx={{marginTop:"40px"}}
      textAlign="center"
    >
      <CircularProgress />
    </Box>
    </>
  );
} else if (fitPrintUser && isLoaded && fitPrintUser.style){
    return (
      <React.Fragment>
        <ButtonAppBar />
        <CssBaseline />
        <Container
          maxWidth="100%"
          sx={{
            minWidth: "1155px",
            padding: "10px",
            bgcolor: "#F2F2F2",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Popover
              id={copyPopOverId}
              open={copyPopOverOpen}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}

            >
              <Typography sx={{p: 2 }}>Copied!</Typography>
            </Popover>
            <WelcomeNewUserModal
              open={openHelpModal}
              onClose={() => setOpenHelpModal(false)}
            />
            <EmbedCodeModal
              open={openEmbedCodeModal}
              onClose={() => setOpenEmbedCodeModal(false)}
            />
            <Grid container spacing={2}>
              <Grid item xs={7}>
              <IconButton
                aria-label="help"
                size="small"
                onClick={handleOpenHelpModal}
                sx={{float: "right", marginTop: "10px"}}
              >
                <HelpOutlineIcon fontSize="inherit" />
              </IconButton>
              <ShopForm
                fitPrintUser={fitPrintUser}
                saveButtonClicked={handleSaveButtonClicked}
              />
              </Grid>
              <Grid item xs={5}>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <PreviewIcon sx={{marginTop:'5px'}} />
                </Grid>
                <Grid item>
                <Typography
                  align="left"
                  variant="h6"
                  gutterBottom
                  component="div"
                >
                  {" "}
                  <Box
                    sx={{
                      fontWeight: "bold",
                      m: 1,
                    }}
                  >
                    Page Preview
                  </Box>{" "}
                </Typography>
                </Grid>
              </Grid>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel onChange={setPreviewUrl} checked={previewPreference === "products"} value="products" control={<Radio />} label="Printify Products" />
                    <FormControlLabel onChange={setPreviewUrl} checked={previewPreference === "links"} value="links" control={<Radio />} label="Affiliate Links" />
                  </RadioGroup>
                </FormControl>
                <Box
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid #EBEBEB",
                  }}
                >
                  <Stack spacing={1}>
                    <Box
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        width: "100%",
                        // borderBottom: "1px solid grey",
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{marginTop:'9px'}}
                      >
                        <Box
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            height: "50px",
                            borderTop: "1px solid #EBEBEB",
                            borderLeft: "1px solid #EBEBEB",
                            borderRight: "1px solid #EBEBEB",
                            width: "150px",
                            borderRadius: 2,
                            marginBottom: "-25px",
                            bgcolor: "white"
                          }}
                        >
                          <Typography
                            align="left"
                            variant="caption"
                            sx={{ lineHeight: "30px", marginLeft: "12px" }}
                          >
                            {fitPrintUser.shopName
                              ? fitPrintUser.shopName
                              : "Your Store Url"}
                          </Typography>
                        </Box>
                        <Button
                          variant="contained"
                          onClick={handleOpenEmbedCodeModal}
                          sx={{ height: "25px", fontSize: "10px" }}
                        >
                          Embed Code
                        </Button>
                      </Stack>
                      <Box
                        display="flex"
                        sx={{
                          bgcolor: "white",
                          height: "1px",
                          borderTop: "1px solid #EBEBEB",
                          padding: "5px",
                          marginLeft: "150px",
                          marginTop: "10px",
                          marginBottom: "-18px",
                        }}
                      ></Box>
                    </Box>

                    <Box
                      display="flex"
                      sx={{
                        width: "100%",
                        bgcolor: "white",
                        height: "40px",
                        borderLeft: "1px solid #EBEBEB",
                        borderRight: "1px solid #EBEBEB",
                        padding: "5px",
                      }}
                    >
                      <Box
                        display="flex"
                        sx={{
                          width: "100%",
                          borderRadius: 2,
                          bgcolor: "#EBEBEB",
                          height: "30px",
                        }}
                      >
                        <Stack direction="row" justifyContent="space-between">
                          <Typography
                            align="left"
                            variant="caption"
                            component="div"
                            sx={{ marginLeft: "10px", lineHeight: "30px", fontSize: "10px" }}
                          >
                            {pageSrc}
                          </Typography>
                          <IconButton
                            aria-label="copy"
                            size="small"
                            onClick={handleCopyUrlClicked}
                          >
                            <ContentCopyIcon fontSize="inherit" />
                          </IconButton>
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Box>

                <Box
                  sx={{
                    bgcolor: "#ffffff",
                    height: "900px",
                    paddingTop: "5px",
                    border: "1px solid #EBEBEB",
                    borderRadius: "5px",
                    // padding:"10px"
                  }}
                >
                  <iframe
                    id="FitPrintIframe"
                    title={Date.now()}
                    frameBorder="0"
                    scrolling="yes"
                    src={iframeSrc}
                    width="100%"
                    height="100%"
                    overflow="scroll"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </React.Fragment>
    );
  } else {
    return (
      <>
      <ButtonAppBar />
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      sx={{marginTop:"40px"}}
      textAlign="center"
    >
      Error
    </Box>
    </>
  );;
  }
};
export default Home;
