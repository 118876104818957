import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Example from "./example.js";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useStore from "../store.js";
import Grid from "@mui/material/Grid";
import MoneyInput from "@rschpdr/react-money-input/dist/index.js";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Popover from "@mui/material/Popover";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateCategoryModal from "./createCategoryModal.js";
import uuid from "react-native-uuid";
import Amplify, { API, Storage } from "aws-amplify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "1px solid #EBEBEB",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const CreateAffiliateLinksModal = ({ onSave }) => {
  const linkId = uuid.v4();
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const imageBucketURL =
    "https://fitprintapic3fb212387df4b2a80f7ea6fd02e165971540-prod.s3.us-east-2.amazonaws.com/public/";
  const addAffiliateLink = useStore((state) => state.addAffiliateLink);
  const userCategories = useStore((state) => state.categories);

  const [currentTitle, setCurrentTitle] = React.useState("");
  function handleTitleChange(event) {
    setCurrentTitle(event.target.value);
  }

  const [currentURL, setCurrentURL] = React.useState("");
  function handleURLChange(event) {
    setCurrentURL(event.target.value);
  }

  const [currentLabel, setCurrentLabel] = React.useState("");
  function handleCurrentLabelChange(event) {
    setCurrentLabel(event.target.value);
  }

  const [currentSubTitle, setCurrentSubTitle] = React.useState("");
  function handleCurrentSubTitleChange(event) {
    setCurrentSubTitle(event.target.value);
  }

  const [buttonText, setButtonText] = React.useState("");
  function handleButtonTextChange(event) {
    setButtonText(event.target.value);
  }

  const [category, setCategory] = React.useState("");
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const [description, setDescription] = React.useState("");
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const [imageURL, setImageURL] = React.useState("");

  async function uploadImage(e) {
    const file = e.target.files[0];

    try {
      await Storage.put(
        `${user.attributes.sub}/LinkImages/${linkId}.${file.name
          .split(".")
          .pop()}`,
        file,
        {
          contentType: "image/png",
          cacheControl: "no-cache",
          resumable: true,
          completeCallback: (event) => {
            console.log(`Successfully uploaded ${event.key}`);
            console.log(`Successfully uploaded ${event}`);
            let path = event.key.substring(event.key.indexOf("/") + 1);
            let newImageURL = imageBucketURL + path;
            console.log(newImageURL);
            setImageURL(newImageURL);
          },
          progressCallback: (progress) => {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        }
      );
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  function handleImageURLChange(event) {
    setImageURL(event.target.value);
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    addAffiliateLink({
      category: category,
      price: currentLabel,
      title: currentTitle,
      subTitle: currentSubTitle,
      description: description,
      imageURL: imageURL,
      affiliateLink: currentURL,
      buttonText: buttonText,
    });
    setCurrentTitle("");
    setCurrentURL("");
    setCurrentLabel("");
    setCurrentSubTitle("");
    setButtonText("");
    setCategory("");
    setDescription("");
    setImageURL("");
    onSave();
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        fullWidth
        sx={{
          float: "left",
          padding: "10px",
        }}
        onClick={handleOpen}
      >
        Create a Link
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create an Affiliate Link
          </Typography>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { marginTop: "10px", marginBottom: "10px" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Link Title"
                      variant="outlined"
                      value={currentTitle}
                      onChange={handleTitleChange}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Link URL"
                      variant="outlined"
                      value={currentURL}
                      onChange={handleURLChange}
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Link Subtitle"
                      variant="outlined"
                      value={currentSubTitle}
                      onChange={handleCurrentSubTitleChange}
                      helperText="Displayed Under Title"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Link Label"
                      variant="outlined"
                      value={currentLabel}
                      onChange={handleCurrentLabelChange}
                      helperText="Bold Text Under Subtitle"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Button Text"
                      variant="outlined"
                      value={buttonText}
                      onChange={handleButtonTextChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        label="Category"
                        onChange={handleCategoryChange}
                      >
                        {userCategories.map((cat, i) => (
                          <MenuItem value={cat.id}>{cat.title}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <Box sx={{ width: "100%", marginTop: "0px" }}>
                      <CreateCategoryModal />
                    </Box>
                  </Grid>

                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="banner"
                      label="Image URL"
                      value={imageURL}
                      onChange={handleImageURLChange}
                      helperText="URL for image or upload your own"
                    />{" "}
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      component="label"
                      sx={{ marginTop: "17px" }}
                    >
                      Upload Image
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        hidden
                        onChange={(e) => uploadImage(e)}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Description"
                      multiline
                      fullWidth
                      rows={3}
                      value={description}
                      onChange={handleDescriptionChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "20px" }}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button variant="contained" color="error" onClick={handleClose}>
                  Close
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" color="success" onClick={handleSave}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateAffiliateLinksModal;
