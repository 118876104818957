import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";
import { useAuthenticator } from "@aws-amplify/ui-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const DisconnectPrintifyModal = ({ open, onClose }) => {
  const handleDisconnectButtonClicked = () => {
    onClose(true);
  };

  const handleCloseButtonClicked = () => {
    onClose(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Disconnect Your Printify Account?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Click DISCONNECT to remove the current link between Printify and FitPrint. This will allow you to connect this FitPrint Account to another Printify Account by toggling the switch back on.
          </Typography>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "20px" }}
          >
            <Button color= "warning" variant="contained" onClick={handleCloseButtonClicked} sx={{ marginRight: "10px" }} >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDisconnectButtonClicked} sx={{ marginLeft: "10px" }}>
              Disconnect
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DisconnectPrintifyModal;
