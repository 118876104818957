import update from 'immutability-helper'
import { useCallback, useState } from 'react'
import { AffiliateLinkCard } from './affiliateLinkCard.js'
import useStore from '../store.js';

const style = {
  width: 550,
}
const ArrangeableLinksContainer = () => {
    const userLinks = useStore((state) => state.affiliateLinks);
    const removeArray = useStore((state) => state.removeAffiliateLink);
    const moveArray = useStore((state) => state.moveAffiliateLink);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
      moveArray(dragIndex, hoverIndex)
    }, [])

    const deleteCard = useCallback((index) => {
    removeArray(index)
    }, [])

    const renderCard = useCallback((card, index) => {
      return (
        <AffiliateLinkCard
          key={card.id}
          index={index}
          id={card.id}
          title={card.title}
          url={card.imageURL}
          moveCard={moveCard}
          deleteCard={deleteCard}
        />
      )
    }, [])
    return (
      <>
        <div style={style}>{userLinks.map((card, i) => renderCard(card, i))}</div>
      </>
    )
}

export default ArrangeableLinksContainer;
