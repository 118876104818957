import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Example from "./example.js";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useStore from "../store.js";
import Cropper from "react-cropper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "cropperjs/dist/cropper.css";
import "./cropper.css";

const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "1040px",
  bgcolor: "background.paper",
  border: "1px solid #EBEBEB",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const ImageCropperModal = ({ open, onClose }) => {
  const handleCloseButtonClicked = () => {
    onClose();
  };

  const [image, setImage] = useState(defaultSrc);
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState(null);
  const onCropperChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container>

            <Box sx={style}>
            <Grid
              container
              spacing={2}
              sx={{
                maxWidth: "1040px",
              }}
            >
            <Grid item xs={8}>
              <Item>xs=8</Item>
            </Grid>
            <Grid item xs={4}>
              <Item>xs=4</Item>
            </Grid>
            <Grid item xs={4}>
              <Item>xs=4</Item>
            </Grid>
            <Grid item xs={8}>
              <Item>xs=8</Item>
            </Grid>
              <div>
                <div style={{ width: "100%" }}>
                  <input type="file" onChange={onCropperChange} />
                  <button>Use default img</button>
                  <br />
                  <br />
                  <Cropper
                    style={{ height: 400, width: "100%" }}
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    preview=".img-preview"
                    src={image}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={(instance) => {
                      setCropper(instance);
                    }}
                    guides={true}
                  />
                </div>
                <div>
                  <div className="box" style={{ width: "50%", float: "right" }}>
                    <h1>Preview</h1>
                    <div
                      className="img-preview"
                      style={{ width: "100%", float: "left", height: "300px" }}
                    />
                  </div>
                  <div
                    className="box"
                    style={{ width: "50%", float: "right", height: "300px" }}
                  >
                    <h1>
                      <span>Crop</span>
                      <button style={{ float: "right" }} onClick={getCropData}>
                        Crop Image
                      </button>
                    </h1>
                    <img
                      style={{ width: "100%" }}
                      src={cropData}
                      alt="cropped"
                    />
                  </div>
                </div>
                <br style={{ clear: "both" }} />
              </div>

              <Box
                alignItems="center"
                display="flex"
                justifyContent="center"
                textAlign="center"
                sx={{ marginTop: "20px" }}
              >
                <Button variant="contained" onClick={handleCloseButtonClicked}>
                  Close
                </Button>
              </Box>
              </Grid>
            </Box>

        </Container>
      </Modal>
    </div>
  );
};

export default ImageCropperModal;
