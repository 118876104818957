import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from './home';
import PrintifyConnectionSuccess from './printifyConnectionSuccess';
import StripeConnectionSuccess from './stripeConnectionSuccess';
import FitPrintProducts from './fitprintProducts';
import CreateNewProduct from './createNewProduct';
import FitPrintProPurchaseSuccess from './fitprintProPurchaseSuccess.js';

const Webpages = () => {
    return(
        <BrowserRouter>
          <Routes>
              <Route exact={true} path="/" element={<Home />} />
              <Route path ="/fitPrintProducts" element={<FitPrintProducts />} />
              <Route path ="/createNewProduct" element={<CreateNewProduct />} />
              <Route path ="/printifyConnectionSuccess" element={<PrintifyConnectionSuccess />} />
              <Route path ="/stripeConnectionSuccess" element={<StripeConnectionSuccess />} />
              <Route path ="/fitPrintProPurchaseSuccess" element={<FitPrintProPurchaseSuccess />} />
            </Routes>
        </BrowserRouter>
    );
};
export default Webpages;
