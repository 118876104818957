import React, { useState, useEffect } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ButtonAppBar from "../components/navBar";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Amplify, { API } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";

const PrintifyConnectionSuccess = (props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const { grantCode } = useParams();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const apiName = "FitPrintAPI"; // replace this with your api name.
    const path = `/private/connectPrintifyAccount/${
      user.attributes.sub
    }/${searchParams.get("grant_code")}`;
    const myInit = {
      body: {},
    };
    API.post(apiName, path, myInit)
      .then((response) => {
        setIsSuccess(true);
        console.log(response);
        navigate('/')
      })
      .catch((error) => {
        setError(error);
        console.log(error.response);
        alert(error.response);
        navigate('/')
      });
  }, []);

  if (error) {
    return <div>Error</div>;
  }
  if (!isLoaded) {
    return (
      <>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          sx={{ marginTop: "40px" }}
          textAlign="center"
        >
          <CircularProgress />
        </Box>
      </>
    );
  }

  if (isSuccess) {
    return (
      <>
        <ButtonAppBar />
        <CssBaseline />
        <Container
          maxWidth="lg"
          sx={{
            minWidth: "1155px",
            padding: "10px",
          }}
        >
          <Typography sx={{ p: 2 }}>
            Code = {searchParams.get("grant_code")}
          </Typography>
        </Container>
      </>
    );
  }
};
export default PrintifyConnectionSuccess;
