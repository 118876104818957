import React, { useState, useEffect, useCallback } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ColorPicker from "../components/colorPicker";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Badge from "@mui/material/Badge";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Amplify, { API, Storage } from "aws-amplify";
import awsconfig from "../aws-exports.js";
import PassStripeFeesModal from "./passStripeFeesModal.js";
import PassFitPrintFeesModal from "./passFitPrintFeesModal.js";
import CollectSalesTaxModal from "./collectSalesTaxModal.js";
import CollectSalesTaxInfoModal from "./collectSalesTaxInfoModal.js";
import ConnectToPrintifyModal from "./connectToPrintifyModal.js";
import DisconnectPrintifyModal from "./disconnectPrintifyModal.js";
import ConnectToStripeModal from "./connectToStripeModal.js";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CircularProgress from "@mui/material/CircularProgress";
import ConnectionStatusBadge from "./connectionStatusBadge.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CreateUserLinksModal from "../components/createUserLinksModal.js";
import ManageAffiliateLinksModal from "./manageAffiliateLinksModal.js";
import useStore from "../store.js";
import MenuIcon from "@mui/icons-material/Menu";
import ImageCropperModal from "./imageCropperModal.js";
import uuid from "react-native-uuid";
import PaidIcon from "@mui/icons-material/Paid";
import LinkIcon from "@mui/icons-material/Link";
import StoreIcon from "@mui/icons-material/Store";
import StyleIcon from "@mui/icons-material/Style";
import SellIcon from "@mui/icons-material/Sell";
import SaveIcon from "@mui/icons-material/Save";
import Fab from "@mui/material/Fab";
import CreateAffiliateLinksModal from "./createAffiliateLinksModal.js";
import UpgradeToProModal from "./upgradeToProModal.js";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

Amplify.configure(awsconfig);
const imageBucketURL =
  "https://fitprintapic3fb212387df4b2a80f7ea6fd02e165971540-prod.s3.us-east-2.amazonaws.com/public/";
// const imageBucketURL = "https://fitprintapic3fb212387df4b2a80f7ea6fd02e165963809-dev.s3.us-east-2.amazonaws.com/public/"

const ShopForm = ({ fitPrintUser, saveButtonClicked }) => {
  const [needsUpdate, setNeedsUpdate] = React.useState(false);

  let navigate = useNavigate();

  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [localFitPrintUser, setLocalFitPrintUser] = React.useState(
    fitPrintUser
  );
  const [shopName, setShopName] = React.useState(fitPrintUser.shopName);
  const [shopURL, setShopURL] = React.useState(fitPrintUser.redirectUri);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [bannerImageURL, setBannerImageURL] = React.useState(
    fitPrintUser.bannerImageURL
  );
  const [profileImageURL, setProfileImageURL] = React.useState(
    fitPrintUser.profileImageURL
  );

  const [
    fitPrintPrimaryButtonColor,
    setFitPrintPrimaryButtonColor,
  ] = React.useState(fitPrintUser.style.primaryButtonColor);

  const [
    fitPrintPrimaryButtonFontColor,
    setFitPrintPrimaryButtonFontColor,
  ] = React.useState(fitPrintUser.style.primaryButtonFontColor);

  const [
    fitPrintSecondaryButtonColor,
    setFitPrintSecondaryButtonColor,
  ] = React.useState(fitPrintUser.style.secondaryButtonColor);

  const [
    fitPrintActiveButtonColor,
    setFitPrintActiveButtonColor,
  ] = React.useState(fitPrintUser.style.activeButtonColor);

  const [fitPrintHeaderBgColor, setFitPrintHeaderBgColor] = React.useState(
    fitPrintUser.style.headerBgColor
  );
  const [
    fitPrintHeaderButtonColor,
    setFitPrintHeaderButtonColor,
  ] = React.useState(fitPrintUser.style.headerButtonColor);

  const [
    fitPrintSecondaryFontFamily,
    setFitPrintSecondaryFontFamily,
  ] = React.useState(fitPrintUser.style.secondaryFontFamily);

  const [
    fitPrintPrimaryFontFamily,
    setFitPrintPrimaryFontFamily,
  ] = React.useState(fitPrintUser.style.primaryFontFamily);

  const [fitPrintFontColor, setFitPrintFontColor] = React.useState(
    fitPrintUser.style.fontColor
  );
  const [fitPrintPageBgColor, setFitPrintPageBgColor] = React.useState(
    fitPrintUser.style.pageBgColor
  );
  const [connectedToPrintify, setConnectedToPrintify] = React.useState(
    fitPrintUser.shopId !== 3229439
  );
  const [connectedToStripe, setConnectedToStripe] = React.useState(
    fitPrintUser.stripeId != null
  );
  const [stripeConnectionStatus, setStripeConnectionStatus] = React.useState(
    false
  );
  const [
    stripeConnectionStatusLoading,
    setStripeConnectionStatusLoading,
  ] = React.useState(true);
  const [
    printifyConnectionStatus,
    setPrintifyConnectionStatus,
  ] = React.useState(false);
  const [
    printifyConnectionStatusLoading,
    setPrintifyConnectionStatusLoading,
  ] = React.useState(true);
  const [stripeId, setStripeId] = React.useState(fitPrintUser.stripeId);
  const [
    passStripeFeesToCustomer,
    setPassStripeFeesToCustomer,
  ] = React.useState(fitPrintUser.paymentSettings.passStripeFeesToCustomer);
  const [
    passFitPrintFeesToCustomer,
    setFitPrintFeesToCustomer,
  ] = React.useState(fitPrintUser.paymentSettings.passFitPrintFeesToCustomer);
  const [collectSalesTax, setCollectSalesTax] = React.useState(
    fitPrintUser.paymentSettings.collectSalesTax
  );
  const [
    passStripeFeesModalInfoOnly,
    setPassStripeFeesModalInfoOnly,
  ] = useState(true);
  const [
    passFitPrintFeesModalInfoOnly,
    setPassFitPrintFeesModalInfoOnly,
  ] = useState(true);
  const [openPassStripeFeesModal, setOpenPassStripeFeesModal] = useState(false);
  const [
    openCollectSalesTaxInfoModal,
    setOpenCollectSalesTaxInfoModal,
  ] = useState(false);
  const [openPrintifyModal, setOpenPrintifyModal] = useState(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const handleCloseUpgradeModal = () => setOpenUpgradeModal(false);
  const handleOpenUpgradeModal = () => setOpenUpgradeModal(true);
  const handleClosePrintifyModal = () => setOpenPrintifyModal(false);
  const [
    openDisconnectPrintifyModal,
    setOpenDisconnectPrintifyModal,
  ] = useState(false);
  const handleCloseDisconnectPrintifyModal = (disconnect) => {
    if (disconnect) {
      localFitPrintUser.shopId = 3229439;
      localFitPrintUser.printifyToken =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzN2Q0YmQzMDM1ZmUxMWU5YTgwM2FiN2VlYjNjY2M5NyIsImp0aSI6Ijc1NmRlMmNlNzUwZjg4YzU0NGJiM2IyYmNkOWJkNzcyOWExZjc5MjNiOGRmNGFiYWFjOGE0YmM5ZGJmMzI3NjA0NGQzNjAyZmMwZTg1OTMxIiwiaWF0IjoxNjUwNTcxNzMwLCJuYmYiOjE2NTA1NzE3MzAsImV4cCI6MTY4MjEwNzczMCwic3ViIjoiODQ3MjI3OCIsInNjb3BlcyI6WyJzaG9wcy5tYW5hZ2UiLCJzaG9wcy5yZWFkIiwiY2F0YWxvZy5yZWFkIiwib3JkZXJzLnJlYWQiLCJvcmRlcnMud3JpdGUiLCJwcm9kdWN0cy5yZWFkIiwicHJvZHVjdHMud3JpdGUiLCJ3ZWJob29rcy5yZWFkIiwid2ViaG9va3Mud3JpdGUiLCJ1cGxvYWRzLnJlYWQiLCJ1cGxvYWRzLndyaXRlIiwicHJpbnRfcHJvdmlkZXJzLnJlYWQiXX0.ABDs79OmXqgavV_JDi5AvD9OeD9EsGtOwHjSHTz7Jtensve7BlUfepCw5ucQZe1JXMAFh1YzEDPFbo6nJcA";
      setLocalFitPrintUser(localFitPrintUser);
      handleSubmit();
      saveButtonClicked();
    }
    setOpenDisconnectPrintifyModal(false);
  };
  async function uploadImage(e, imagePath) {
    const file = e.target.files[0];
    setIsLoaded(false);
    try {
      await Storage.put(
        `${user.attributes.sub}/${imagePath}.${file.name.split(".").pop()}`,
        file,
        {
          contentType: "image/png",
          cacheControl: "no-cache",
          resumable: true,
          completeCallback: (event) => {
            setIsLoaded(true);
            console.log(`Successfully uploaded ${event.key}`);
            console.log(`Successfully uploaded ${event}`);
            if (imagePath == "profileImage") {
              let path = event.key.substring(event.key.indexOf("/") + 1);
              let newImageURL = imageBucketURL + path;
              localFitPrintUser.profileImageURL = newImageURL;
              setLocalFitPrintUser(localFitPrintUser);
              setProfileImageURL(newImageURL);
              setNeedsUpdate(true);
            }
            if (imagePath == "bannerImage") {
              let path = event.key.substring(event.key.indexOf("/") + 1);
              let newImageURL = imageBucketURL + path;
              localFitPrintUser.bannerImageURL = newImageURL;
              setLocalFitPrintUser(localFitPrintUser);
              setBannerImageURL(newImageURL);
              setNeedsUpdate(true);
            }
          },
          progressCallback: (progress) => {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          },
          errorCallback: (err) => {
            setIsLoaded(true);
            console.error("Unexpected error while uploading", err);
          },
        }
      );
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }
  const [openImageCropperModal, setOpenImageCropperModal] = useState(false);
  const handleOpenImageCropperModal = () => setOpenImageCropperModal(true);
  const handleCloseImageCropperModal = () => {
    setOpenImageCropperModal(false);
  };
  const [openStripeModal, setOpenStripeModal] = useState(false);
  const handleOpenCollectSalesTaxInfoModal = () =>
    setOpenCollectSalesTaxInfoModal(true);
  const handleCloseCollectSalesTaxInfoModal = () =>
    setOpenCollectSalesTaxInfoModal(false);
  const handleOpenPassStripeFeesModal = (event, infoOnly) => {
    setPassStripeFeesModalInfoOnly(infoOnly);
    setOpenPassStripeFeesModal(true);
  };
  const [openPassFitPrintFeesModal, setOpenPassFitPrintFeesModal] = useState(
    false
  );
  const handleOpenPassFitPrintFeesModal = (event, infoOnly) => {
    setPassFitPrintFeesModalInfoOnly(infoOnly);
    setOpenPassFitPrintFeesModal(true);
  };
  const [openCollectSalesTaxModal, setOpenCollectSalesTaxModal] = useState(
    false
  );
  const handleOpenCollectSalesTaxModal = () =>
    setOpenCollectSalesTaxModal(true);

  function handlePrimaryFontChange(event) {
    setNeedsUpdate(true);
    localFitPrintUser.style.primaryFontFamily = event.target.value;
    setLocalFitPrintUser(localFitPrintUser);
    setFitPrintPrimaryFontFamily(event.target.value);
  }

  function handleSecondaryFontChange(event) {
    setNeedsUpdate(true);
    localFitPrintUser.style.secondaryFontFamily = event.target.value;
    setLocalFitPrintUser(localFitPrintUser);
    setFitPrintSecondaryFontFamily(event.target.value);
  }

  function handleShopNameChange(event) {
    setNeedsUpdate(true);
    localFitPrintUser.shopName = event.target.value;
    setLocalFitPrintUser(localFitPrintUser);
    setShopName(event.target.value);
  }

  function handleProfileImageURLChange(event) {
    setNeedsUpdate(true);
    localFitPrintUser.profileImageURL = event.target.value;
    setLocalFitPrintUser(localFitPrintUser);
    setProfileImageURL(event.target.value);
  }

  function handleBannerImageURLChange(event) {
    setNeedsUpdate(true);
    localFitPrintUser.bannerImageURL = event.target.value;
    setLocalFitPrintUser(localFitPrintUser);
    setBannerImageURL(event.target.value);
  }

  function handleShopURLChange(event) {
    setNeedsUpdate(true);
    localFitPrintUser.redirectUri = event.target.value;
    setLocalFitPrintUser(localFitPrintUser);
    setShopURL(event.target.value);
  }
  const handlePrimaryButtonColorChange = (color, event) => {
    setNeedsUpdate(true);
    localFitPrintUser.style.primaryButtonColor = color;
    setLocalFitPrintUser(localFitPrintUser);
    setFitPrintPrimaryButtonColor(color);
  };

  const handlePrimaryButtonFontColorChange = (color, event) => {
    setNeedsUpdate(true);
    localFitPrintUser.style.primaryButtonFontColor = color;
    setLocalFitPrintUser(localFitPrintUser);
    setFitPrintPrimaryButtonFontColor(color);
  };

  const handleSecondaryButtonColorChange = (color, event) => {
    setNeedsUpdate(true);
    localFitPrintUser.style.secondaryButtonColor = color;
    setLocalFitPrintUser(localFitPrintUser);
    setFitPrintSecondaryButtonColor(color);
  };
  const handleActiveButtonColorChange = (color, event) => {
    localFitPrintUser.style.activeButtonColor = color;
    setLocalFitPrintUser(localFitPrintUser);
    setFitPrintActiveButtonColor(color);
  };
  const handleHeaderBgColorChange = (color, event) => {
    setNeedsUpdate(true);
    localFitPrintUser.style.headerBgColor = color;
    setLocalFitPrintUser(localFitPrintUser);
    setFitPrintHeaderBgColor(color);
  };
  const handleHeaderButtonColorChange = (color, event) => {
    setNeedsUpdate(true);
    localFitPrintUser.style.headerButtonColor = color;
    setLocalFitPrintUser(localFitPrintUser);
    setFitPrintHeaderButtonColor(color);
  };

  const handleFontColorChange = (color, event) => {
    setNeedsUpdate(true);
    localFitPrintUser.style.fontColor = color;
    setLocalFitPrintUser(localFitPrintUser);
    setFitPrintFontColor(color);
  };

  const handlepageColorChange = (color, event) => {
    setNeedsUpdate(true);
    localFitPrintUser.style.pageBgColor = color;
    setLocalFitPrintUser(localFitPrintUser);
    setFitPrintPageBgColor(color);
  };

  const handlePassStripeFeesSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.checked) {
      handleOpenPassStripeFeesModal(event, false);
    }
    localFitPrintUser.paymentSettings.passStripeFeesToCustomer =
      event.target.checked;
    setLocalFitPrintUser(localFitPrintUser);
    setPassStripeFeesToCustomer(event.target.checked);
    setNeedsUpdate(true);
  };

  const handlePassFitPrintFeesSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.checked) {
      handleOpenPassFitPrintFeesModal(event, false);
    }
    localFitPrintUser.paymentSettings.passFitPrintFeesToCustomer =
      event.target.checked;
    setLocalFitPrintUser(localFitPrintUser);
    setFitPrintFeesToCustomer(event.target.checked);
    setNeedsUpdate(true);
  };

  const handleCollectSalesTaxSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      handleOpenCollectSalesTaxModal();
    }
    localFitPrintUser.paymentSettings.collectSalesTax = event.target.checked;
    setLocalFitPrintUser(localFitPrintUser);
    setCollectSalesTax(event.target.checked);
    setNeedsUpdate(true);
  };

  const handleConnectToPrintifySwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setOpenPrintifyModal(true);
    } else {
      setOpenDisconnectPrintifyModal(true);
    }
  };

  const handleConnectToStripeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setOpenStripeModal(true);
    }
    setConnectedToStripe(event.target.checked);
  };

  const handleTaxRateModalClose = (taxRateId) => {
    localFitPrintUser.taxRateId = taxRateId;
    setLocalFitPrintUser(localFitPrintUser);
    handleSubmit();
    setOpenCollectSalesTaxModal(false);
  };
  const handleCloseStripeModal = () => {
    setOpenStripeModal(false);
  };

  function navigateToProducts(event) {
    navigate("/fitPrintProducts");
  }

  const userLinks = useStore((state) => state.userLinks);
  const [openUserLinksModal, setOpenUserLinksModal] = useState(false);
  const handleOpenUserLinksModal = () => setOpenUserLinksModal(true);
  const handleCloseUserLinksModal = () => {
    setOpenUserLinksModal(false);
  };
  const handleSaveUserLinksModal = () => {
    localFitPrintUser.links = userLinks;
    setLocalFitPrintUser(localFitPrintUser);
    setOpenUserLinksModal(false);
    handleSubmit();
    saveButtonClicked();
  };

  const [openAffiliateLinksModal, setOpenAffiliateLinksModal] = useState(false);
  const handleOpenAffiliateLinksModal = () => setOpenAffiliateLinksModal(true);
  const handleCloseAffiliateLinksModal = () => {
    setOpenAffiliateLinksModal(false);
  };

  const [
    openManageAffiliateLinksModal,
    setOpenManageAffiliateLinksModal,
  ] = useState(false);
  const handleOpenManageAffiliateLinksModal = () =>
    setOpenManageAffiliateLinksModal(true);
  const handleCloseManageAffiliateLinksModal = () => {
    setOpenManageAffiliateLinksModal(false);
  };

  const affiliateLinks = useStore((state) => state.affiliateLinks);
  const affiliateLinkCategories = useStore((state) => state.categories);
  const handleSaveManageAffiliateLinksModal = () => {
    localFitPrintUser.affiliateLinks = affiliateLinks;
    localFitPrintUser.affiliateLinkCategories = affiliateLinkCategories;
    setLocalFitPrintUser(localFitPrintUser);
    setOpenManageAffiliateLinksModal(false);
    handleSubmit();
    saveButtonClicked();
  };

  const handleSaveCreateAffiliateLinkModal = () => {
    setOpenManageAffiliateLinksModal(true);
  };

  function handleSubmit(event) {
    // event.preventDefault();

    const apiName = "FitPrintAPI";
    const path = `/private/updateAccount/${user.attributes.sub}`;
    const myInit = {
      body: localFitPrintUser,
    };
    setIsLoaded(false);
    API.post(apiName, path, myInit)
      .then((response) => {
        setIsLoaded(true);
        console.log(response);
        saveButtonClicked();
        setNeedsUpdate(false);
      })
      .catch((error) => {
        setIsLoaded(true);
        setError(error);
        console.log(error.response);
        alert(error.response);
      });
    if (fitPrintUser.shopId === 3229439) {
      setConnectedToPrintify(false);
      setPrintifyConnectionStatus(false);
      setPrintifyConnectionStatusLoading(false);
    }
  }

  useEffect(() => {
    setNeedsUpdate(false);
    if (fitPrintUser.stripeId != null) {
      var config = {
        method: "get",
        url: `https://api.stripe.com/v1/accounts/${fitPrintUser.stripeId}`,
        headers: {
          Authorization:
            "Bearer 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",
        },
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          setStripeConnectionStatusLoading(false);
          setStripeConnectionStatus(
            response.data.charges_enabled && response.data.payouts_enabled
          );
          setConnectedToStripe(true);
        })
        .catch(function (error) {
          setConnectedToStripe(false);
          setStripeConnectionStatus(false);
          setStripeConnectionStatusLoading(false);
          alert(`Error Connecting Account to Stripe ${error}`);
        });
    } else {
      setConnectedToStripe(false);
      setStripeConnectionStatus(false);
      setStripeConnectionStatusLoading(false);
    }
    if (fitPrintUser.shopId !== 3229439) {
      const apiName = "FitPrintAPI";
      const path = `/private/getPrintifyShopId/${user.attributes.sub}`;
      setPrintifyConnectionStatusLoading(true);
      API.get(apiName, path)
        .then((response) => {
          console.log(response);
          setConnectedToPrintify(response.shopId);
          setPrintifyConnectionStatus(response.shopId);
          setPrintifyConnectionStatusLoading(false);

          const webHookPath = `/private/getPrintifyWebHooks/${user.attributes.sub}`;
          API.get(apiName, webHookPath)
            .then((response) => {
              console.log(response);
              setPrintifyConnectionStatusLoading(false);
            })
            .catch((error) => {
              console.log(error);
              setPrintifyConnectionStatusLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setConnectedToPrintify(false);
          setPrintifyConnectionStatus(false);
          setPrintifyConnectionStatusLoading(false);
        });
    } else {
      setConnectedToPrintify(false);
      setPrintifyConnectionStatus(false);
      setPrintifyConnectionStatusLoading(false);
    }
  }, []);

  if (error) {
    return <div>Error</div>;
  } else if (!isLoaded) {
    return (
      <>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          sx={{ marginTop: "40px" }}
          textAlign="center"
        >
          <CircularProgress />
        </Box>
      </>
    );
  } else {
    return (
      <>
        <ImageCropperModal
          open={openImageCropperModal}
          onClose={handleCloseImageCropperModal}
        />
        <CreateUserLinksModal
          open={openUserLinksModal}
          onClose={handleCloseUserLinksModal}
          onSave={handleSaveUserLinksModal}
        />
        <ManageAffiliateLinksModal
          open={openManageAffiliateLinksModal}
          onClose={handleCloseManageAffiliateLinksModal}
          onSave={handleSaveManageAffiliateLinksModal}
        />
        <PassStripeFeesModal
          open={openPassStripeFeesModal}
          infoOnly={passStripeFeesModalInfoOnly}
          onClose={() => setOpenPassStripeFeesModal(false)}
        />
        <PassFitPrintFeesModal
          open={openPassFitPrintFeesModal}
          infoOnly={passFitPrintFeesModalInfoOnly}
          onClose={() => setOpenPassFitPrintFeesModal(false)}
        />
        <CollectSalesTaxModal
          open={openCollectSalesTaxModal}
          onClose={handleTaxRateModalClose}
          localTaxRateId={fitPrintUser.taxRateId}
        />
        <CollectSalesTaxInfoModal
          open={openCollectSalesTaxInfoModal}
          onClose={handleCloseCollectSalesTaxInfoModal}
        />
        <UpgradeToProModal
          open={openUpgradeModal}
          onClose={handleCloseUpgradeModal}
        />
        <ConnectToPrintifyModal
          open={openPrintifyModal}
          onClose={handleClosePrintifyModal}
        />
        <ConnectToStripeModal
          open={openStripeModal}
          onClose={handleCloseStripeModal}
        />
        <DisconnectPrintifyModal
          open={openDisconnectPrintifyModal}
          onClose={handleCloseDisconnectPrintifyModal}
        />
        <Badge fullWidth color="error" badgeContent=' ' sx={{ position: "fixed", bottom: "65px", right: "27px", zIndex: 1500, display: needsUpdate ? 'block' : 'none' }}>
        </Badge>
          <Fab
            onClick={handleSubmit}
            variant="extended"
            color="primary"
            aria-label="add"
            sx={{ position: "fixed", bottom: "20px", right: "20px" }}
          >
            <SaveIcon sx={{ mr: 1 }} />
            Save and update
          </Fab>


        <Box
          sx={{
            width: "100%",
            borderRadius: 2,
            bgcolor: "#fff",
            p: 1,
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item sx={{ marginTop: "-25px" }}>
              <StoreIcon sx={{ marginTop: "5px" }} />
            </Grid>
            <Grid item sx={{ marginTop: "-25px" }}>
              <Typography
                align="left"
                variant="h6"
                gutterBottom
                component="div"
              >
                {" "}
                <Box
                  sx={{
                    fontWeight: "bold",
                    m: 1,
                  }}
                >
                  Store Settings
                </Box>{" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={4}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  float: "left",
                  padding: "10px",
                  marginTop: "12px",
                }}
                onClick={handleOpenUserLinksModal}
                startIcon={<MenuIcon />}
              >
                Manage Menu Links
              </Button>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                margin="dense"
                id="shopText"
                label="Shop Name"
                value={shopName}
                onChange={handleShopNameChange}
              />{" "}
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item xs={9}>
              <TextField
                fullWidth
                margin="dense"
                id="banner"
                label="Banner Image URL"
                value={bannerImageURL}
                onChange={handleBannerImageURLChange}
                helperText="URL for banner image or upload your own"
              />{" "}
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                component="label"
                sx={{ marginTop: "-20px" }}
              >
                Upload Image
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  hidden
                  onChange={(e) => uploadImage(e, "bannerImage")}
                />
              </Button>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item xs={9}>
              <TextField
                fullWidth
                margin="dense"
                id="profileimg"
                label="Profile Image URL"
                value={profileImageURL}
                onChange={handleProfileImageURLChange}
                helperText="URL for profile image or upload your own"
              />{" "}
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                component="label"
                sx={{ marginTop: "-20px" }}
              >
                Upload Image
                <input
                  type="file"
                  hidden
                  onChange={(e) => uploadImage(e, "profileImage")}
                />
              </Button>
            </Grid>
          </Grid>
          <TextField
            fullWidth
            margin="dense"
            id="shopUrl"
            label="Shop URL"
            value={shopURL}
            onChange={handleShopURLChange}
            helperText="Enter the URL where the shop will be displayed. Leave as is if you do not have your own website yet"
          />{" "}
        </Box>
        <Box
          sx={{
            width: "100%",
            borderRadius: 2,
            bgcolor: "#fff",
            p: 1,
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <StyleIcon sx={{ marginTop: "5px" }} />
            </Grid>
            <Grid item>
              <Typography
                align="left"
                variant="h6"
                gutterBottom
                component="div"
              >
                {" "}
                <Box
                  sx={{
                    fontWeight: "bold",
                    m: 1,
                  }}
                >
                  Style Settings
                </Box>{" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            spacing={2}
          >
            <Grid item xs={6}>
              <React.Fragment>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="body"
                    gutterBottom
                    component="div"
                  >
                    Primary Button Color
                  </Typography>{" "}
                  <ColorPicker
                    parentCallback={handlePrimaryButtonColorChange}
                    initialColor={fitPrintPrimaryButtonColor}
                  />{" "}
                </Stack>
              </React.Fragment>
            </Grid>{" "}
            <Grid item xs={6}>
              {" "}
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Typography
                  align="left"
                  variant="body"
                  gutterBottom
                  component="div"
                >
                  Primary Button Font Color
                </Typography>
                <ColorPicker
                  parentCallback={handlePrimaryButtonFontColorChange}
                  initialColor={fitPrintPrimaryButtonFontColor}
                />{" "}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Typography
                  align="left"
                  variant="body"
                  gutterBottom
                  component="div"
                >
                  Secondary Button Color
                </Typography>{" "}
                <ColorPicker
                  parentCallback={handleSecondaryButtonColorChange}
                  initialColor={fitPrintSecondaryButtonColor}
                />{" "}
              </Stack>
            </Grid>{" "}
            <Grid item xs={6}>
              {" "}
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Typography
                  align="left"
                  variant="body"
                  gutterBottom
                  component="div"
                >
                  Active Button Color
                </Typography>
                <ColorPicker
                  parentCallback={handleActiveButtonColorChange}
                  initialColor={fitPrintActiveButtonColor}
                />{" "}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Typography
                  align="left"
                  variant="body"
                  gutterBottom
                  component="div"
                >
                  Product Header BG Color
                </Typography>{" "}
                <ColorPicker
                  parentCallback={handleHeaderBgColorChange}
                  initialColor={fitPrintHeaderBgColor}
                />{" "}
              </Stack>
            </Grid>{" "}
            <Grid item xs={6}>
              {" "}
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Typography
                  align="left"
                  variant="body"
                  gutterBottom
                  component="div"
                >
                  Header Font Color
                </Typography>
                <ColorPicker
                  parentCallback={handleHeaderButtonColorChange}
                  initialColor={fitPrintHeaderButtonColor}
                />{" "}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Primary Font
                </InputLabel>{" "}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={fitPrintPrimaryFontFamily}
                  label="Primary Font"
                  onChange={handlePrimaryFontChange}
                >
                  <MenuItem value={"'Roboto', sans-serif"}>Roboto</MenuItem>
                  <MenuItem value={"'Oswald', sans-serif"}>Oswald</MenuItem>
                  <MenuItem value={"'Courier', sans-serif"}>Courier</MenuItem>
                  <MenuItem value={"'Poppins', sans-serif"}>Poppins</MenuItem>
                </Select>{" "}
              </FormControl>
            </Grid>{" "}
            <Grid item xs={6}>
              {" "}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Secondary Font
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={fitPrintSecondaryFontFamily}
                  label="Secondary Font"
                  onChange={handleSecondaryFontChange}
                >
                  <MenuItem value={"'Roboto', sans-serif"}>Roboto</MenuItem>
                  <MenuItem value={"'Oswald', sans-serif"}>Oswald</MenuItem>
                  <MenuItem value={"'Courier', sans-serif"}>Courier</MenuItem>
                  <MenuItem value={"'Poppins', sans-serif"}>Poppins</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Typography
                  align="left"
                  variant="body"
                  gutterBottom
                  component="div"
                >
                  Font Color
                </Typography>
                <ColorPicker
                  parentCallback={handleFontColorChange}
                  initialColor={fitPrintFontColor}
                />{" "}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Typography
                  align="left"
                  variant="body"
                  gutterBottom
                  component="div"
                >
                  Page Background Color
                </Typography>
                <ColorPicker
                  parentCallback={handlepageColorChange}
                  initialColor={fitPrintPageBgColor}
                />{" "}
              </Stack>
            </Grid>
          </Grid>{" "}
        </Box>
        <Box
          sx={{
            width: "100%",
            borderRadius: 2,
            bgcolor: "#fff",
            p: 1,
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <SellIcon sx={{ marginTop: "5px" }} />
            </Grid>
            <Grid item>
              <Typography
                align="left"
                variant="h6"
                gutterBottom
                component="div"
              >
                {" "}
                <Box
                  sx={{
                    fontWeight: "bold",
                    m: 1,
                  }}
                >
                  Products Page
                </Box>{" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            spacing={2}
          >
            <Grid item xs={6}>
              {" "}
              <Stack
                direction="row"
                justifyContent="flex-start"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={connectedToPrintify}
                        onChange={handleConnectToPrintifySwitch}
                      />
                    }
                    label="Connect To Printify"
                  />
                </FormGroup>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  textAlign="center"
                >
                  <ConnectionStatusBadge
                    isConnected={printifyConnectionStatus}
                    isLoading={printifyConnectionStatusLoading}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <Badge badgeContent={"Coming Soon"} color="primary">
                <Button
                  disabled
                  variant="contained"
                  onClick={navigateToProducts}
                >
                  Add custom Products
                </Button>
              </Badge>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            borderRadius: 2,
            bgcolor: "#fff",
            p: 1,
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <PaidIcon sx={{ marginTop: "5px" }} />
            </Grid>
            <Grid item>
              <Typography
                align="left"
                variant="h6"
                gutterBottom
                component="div"
              >
                {" "}
                <Box
                  sx={{
                    fontWeight: "bold",
                    m: 1,
                  }}
                >
                  Payments
                </Box>{" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            spacing={2}
          >
            <Grid item xs={6}>
              {" "}
              <Stack
                direction="row"
                justifyContent="flex-start"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={connectedToStripe}
                        onChange={handleConnectToStripeSwitch}
                      />
                    }
                    label="Connect To Stripe"
                  />
                </FormGroup>
                <ConnectionStatusBadge
                  isConnected={stripeConnectionStatus}
                  isLoading={stripeConnectionStatusLoading}
                />
              </Stack>
              <Typography
                align="left"
                variant="caption"
                display="block"
                gutterBottom
                sx={{ marginRight: "20px" }}
              >
                {fitPrintUser.stripeId != null && !stripeConnectionStatus
                  ? "Recently created  Stripe accounts may need to be verified by email. Refresh the page after a few minutes to check the status again."
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  justifyContent: "center",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={passStripeFeesToCustomer}
                        onChange={handlePassStripeFeesSwitch}
                        size="small"
                      />
                    }
                    labelPlacement="end"
                    label={
                      <>
                        <Grid container spacing="1" alignItems="center">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ paddingLeft: "5px" }}
                          >
                            Pass Stripe Fees to customer
                          </Typography>{" "}
                          <IconButton
                            onClick={(event) =>
                              handleOpenPassStripeFeesModal(event, true)
                            }
                            aria-label="info"
                            size="small"
                          >
                            <InfoIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={passFitPrintFeesToCustomer}
                        onChange={handlePassFitPrintFeesSwitch}
                        size="small"
                      />
                    }
                    label={
                      <>
                        <Grid container spacing="1" alignItems="center">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ paddingLeft: "5px" }}
                          >
                            Pass FitPrint Fees to customer
                          </Typography>{" "}
                          <IconButton
                            onClick={(event) =>
                              handleOpenPassFitPrintFeesModal(event, true)
                            }
                            aria-label="info"
                            size="small"
                          >
                            <InfoIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={collectSalesTax}
                        onChange={handleCollectSalesTaxSwitch}
                        size="small"
                      />
                    }
                    label={
                      <>
                        <Grid container spacing="1" alignItems="center">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ paddingLeft: "5px" }}
                          >
                            Collect Sales Tax
                          </Typography>{" "}
                          <IconButton
                            onClick={handleOpenCollectSalesTaxInfoModal}
                            aria-label="info"
                            size="small"
                          >
                            <InfoIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                      </>
                    }
                  />
                </FormGroup>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            borderRadius: 2,
            bgcolor: "#fff",
            p: 1,
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <LinkIcon sx={{ marginTop: "5px" }} />
            </Grid>
            <Grid item>
              <Typography
                align="left"
                variant="h6"
                gutterBottom
                component="div"
              >
                {" "}
                <Box
                  sx={{
                    fontWeight: "bold",
                    m: 1,
                  }}
                >
                  Affiliate Links Page
                </Box>{" "}
              </Typography>
            </Grid>
            <Grid item>
            {((typeof fitPrintUser.showSponsoredLinks !== 'undefined') ? fitPrintUser.showSponsoredLinks : true) ? <Button
              variant="contained"
              startIcon={<VerifiedUserIcon />}
              size="small"
              color="success"
              fullWidth
              sx={{
                float: "right",
                borderRadius: "10px",
              }}
              onClick={handleOpenUpgradeModal}
            >
              Upgrade To Pro
            </Button> : <VerifiedUserIcon /> }
            </Grid>
          </Grid>
          <Grid container direction="row" columnSpacing={6} rowSpacing={2}>
            <Grid item xs={12}></Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  float: "left",
                  padding: "10px",
                }}
                onClick={handleOpenManageAffiliateLinksModal}
              >
                Manage Affiliate Link Page
              </Button>
            </Grid>
            <Grid item xs={6}>
              <CreateAffiliateLinksModal
                onSave={handleSaveCreateAffiliateLinkModal}
              />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
};

export default ShopForm;
