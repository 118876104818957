import React, { useState, useEffect, useCallback } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import ButtonAppBar from "../components/navBar";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ShopForm from "../components/shopForm";
import Amplify, { API, Storage } from "aws-amplify";
import awsconfig from "../aws-exports.js";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmbedCodeModal from "../components/embedCodeModal.js";
import WelcomeNewUserModal from "../components/welcomeNewUserModal.js";
import Popover from "@mui/material/Popover";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { AmplifyS3Album } from "@aws-amplify/ui-react/legacy";
import useStore from "../store.js";
import { AmplifyS3Image } from "@aws-amplify/ui-react/legacy";
import uuid from "react-native-uuid";
import TextField from "@mui/material/TextField";
import ImageCropperModal from "../components/imageCropperModal.js";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NavigationIcon from "@mui/icons-material/Navigation";
import SaveIcon from "@mui/icons-material/Save";
import DraggableImageContainer from "../components/draggableImageContainer.js";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import shallow from "zustand/shallow";
import AddCustomSizeModal from "../components/addCustomSizeModal.js";
import AddCustomColorModal from "../components/addCustomColorModal.js";
import AddVariantModal from "../components/addVariantModal.js";
import Badge from "@mui/material/Badge";
import DeleteIcon from "@mui/icons-material/Delete";
import CardMedia from "@mui/material/CardMedia";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const fabStyle = {
  position: "fixed",
  top: 75,
  right: 16,
};

Amplify.configure(awsconfig);

const CreateNewProduct = () => {
  const currentProductId = uuid.v4();
  const globalFitPrintUser = useStore((state) => state.fitPrintUser);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [currentTitle, setCurrentTitle] = React.useState("");
  const localCustomFitPrintProduct = useStore(
    (state) => state.localCustomFitPrintProduct
  );
  const updateLocalProduct = useStore(
    (state) => state.setLocalCustomFitPrintProduct
  );

  const [openImageCropperModal, setOpenImageCropperModal] = useState(false);
  const handleOpenImageCropperModal = () => setOpenImageCropperModal(true);
  const handleCloseImageCropperModal = () => {
    setOpenImageCropperModal(false);
  };

  const [openCustomSizeModal, setOpenCustomSizeModal] = useState(false);
  const handleOpenCustomSizeModal = () => setOpenCustomSizeModal(true);
  const handleCloseCustomSizeModal = () => {
    setOpenCustomSizeModal(false);
  };

  const [openCustomColorModal, setOpenCustomColorModal] = useState(false);
  const handleOpenCustomColorModal = () => setOpenCustomColorModal(true);
  const handleCloseCustomColorModal = () => {
    setOpenCustomColorModal(false);
  };

  const [openAddVariantModal, setOpenAddVariantModal] = useState(false);
  const handleOpenAddVariantModal = () => setOpenAddVariantModal(true);
  const handleCloseAddVariantModal = () => {
    setOpenAddVariantModal(false);
  };

  function handleTitleChange(event) {
    setCurrentTitle(event.target.value);
  }

  async function onChange(e) {
    const file = e.target.files[0];
    try {
      await Storage.put(
        `${
          user.attributes.sub
        }/productImages/${currentProductId}/${uuid.v4()}.${file.name
          .split(".")
          .pop()}`,
        file,
        {
          contentType: "image/png", // contentType is optional
          resumable: true,
          completeCallback: (event) => {
            console.log(`Successfully uploaded ${event.key}`);
          },
          progressCallback: (progress) => {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        }
      );
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  function handleSubmit(event) {
    console.log(user.attributes.sub);
  }

  function handleOnLoad(event) {
    console.log("Image loaded");
    console.log(event);
  }
  function handleOnError(event) {
    console.log("Image Errored");
    console.log(event);
  }

  function getColorFor(ids) {
    let colors = localCustomFitPrintProduct.options.find(
      (option) => option.type === "color"
    ).values;
    var colorForId = null;
    ids.forEach(function(id) {
      var color = colors.filter(function (color) { return color.id === id; });
      if (color.length > 0) {
        colorForId = color[0];
      }
    });
    return colorForId;
  }

  function getSizeFor(ids) {
    let sizes = localCustomFitPrintProduct.options.find(
          (option) => option.type === "size"
        ).values;

    console.log(ids)
    var sizeForId = null;
    ids.forEach(function(id) {
      var size = sizes.filter(function (size) { return size.id === id; });
      if (size.length > 0) {
        sizeForId = size[0];
      }
    });
    return sizeForId;
  }

  const renderSizeCheckbox = useCallback((size, index) => {
    return (
      <Grid item xs={4}>
        <Item>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            textAlign="center"
          >
            <Typography variant="body1">{size.title}</Typography>
            <IconButton
              aria-label="delete"
              onClick={() => {
                // deleteCard(id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Item>
      </Grid>
    );
  }, []);

  const renderVariantItem = useCallback((variant, index) => {
    return (
      <Grid item xs={12}>
        <Item>
          <Grid
            container
            spacing={2}
            sx={{
              maxWidth: "1040px",
            }}
          >
          <Grid item xs={1.5}>
            <Typography variant="body1">{getSizeFor(variant.options).title}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography variant="body1">{getColorFor(variant.options).title}</Typography>
          </Grid>
            <Grid item xs={2}>
              <Typography noWrap variant="body1">{variant.sku}</Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography variant="body1">{variant.title}</Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography variant="body1">{variant.price}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1">{variant.is_default ? <FormControlLabel disabled control={<Checkbox defaultChecked />} /> : <FormControlLabel disabled control={<Checkbox />} />}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1">{variant.is_enabled ? <FormControlLabel disabled control={<Checkbox defaultChecked />} /> : <FormControlLabel disabled control={<Checkbox />} />}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1">{variant.is_available ? <FormControlLabel disabled control={<Checkbox defaultChecked />} /> : <FormControlLabel disabled control={<Checkbox />} />}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Stack
                direction="row"
                justifyContent="left"
                alignItems="center"
                display="flex"
                textAlign="center"
              >
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    // deleteCard(id);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    // deleteCard(id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Item>
      </Grid>
    );
  }, []);

  const renderColorCheckbox = useCallback((color, index) => {
    return (
      <Grid item xs={4}>
        <Item>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            textAlign="center"
          >
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              display="flex"
              textAlign="center"
            >
              <div
                style={{
                  border: "1px solid grey",
                  width: "25px",
                  height: "25px",
                  backgroundColor: color.colors[0],
                }}
              ></div>
              <Typography variant="body1" sx={{ marginLeft: "8px" }}>
                {color.title}
              </Typography>
            </Stack>
            <IconButton
              aria-label="delete"
              onClick={() => {
                // deleteCard(id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Item>
      </Grid>
    );
  }, []);

  return (
    <>
      <ImageCropperModal
        open={openImageCropperModal}
        onClose={handleCloseImageCropperModal}
      />
      <AddCustomSizeModal
        open={openCustomSizeModal}
        onClose={handleCloseCustomSizeModal}
      />
      <AddCustomColorModal
        open={openCustomColorModal}
        onClose={handleCloseCustomColorModal}
      />
      <AddVariantModal
        open={openAddVariantModal}
        onClose={handleCloseAddVariantModal}
      />
      <ButtonAppBar />
      <CssBaseline />
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Fab sx={fabStyle} color="primary">
          <SaveIcon />
        </Fab>
        <Container>
          <Grid
            container
            spacing={2}
            sx={{
              maxWidth: "1040px",
            }}
          >
            <Grid item xs={12}>
              <Typography
                align="left"
                variant="h5"
                gutterBottom
                component="div"
              >
                {" "}
                <Box
                  sx={{
                    fontWeight: "bold",
                    m: 1,
                  }}
                >
                  Create A Product
                </Box>{" "}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Grid item xs={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ marginBottom: "10px" }}
                >
                  Product Title
                </Typography>
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  fullWidth
                  value={localCustomFitPrintProduct.title}
                  onChange={handleTitleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  Description
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  fullWidth
                  rows={8}
                  defaultValue="Default Value"
                  value={localCustomFitPrintProduct.description}
                  onChange={handleTitleChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Product Images
                <Button
                  variant="text"
                  onClick={handleOpenImageCropperModal}
                  size="small"
                  sx={{ float: "right" }}
                >
                  ADD IMAGES
                </Button>{" "}
              </Typography>
              <Item sx={{ marginTop: "10px" }}>
                <Grid item xs={12}>
                  <Typography id="modal-modal-title" variant="body1">
                    Main Image
                  </Typography>
                  <CardMedia
                    component="img"
                    height="182"
                    image="https://www.kneehighsurfco.com/assets/images/wetsuit.jpg"
                    alt="MAin Image"
                    sx={{ marginTop: "4px", objectFit: "contain" }}
                  />
                  <Grid item xs={12} sx={{ marginTop: "20px" }}>
                    <DndProvider backend={HTML5Backend}>
                      <DraggableImageContainer />
                    </DndProvider>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Sizes
                <Button
                  variant="text"
                  onClick={handleOpenCustomSizeModal}
                  size="small"
                  sx={{ float: "right" }}
                >
                  Add a size
                </Button>{" "}
              </Typography>
              <Grid item xs={12}>
                <Item>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      maxWidth: "1040px",
                    }}
                  >
                    {localCustomFitPrintProduct.options.find(
                          (option) => option.type === "size"
                        ).values.map((size, i) => renderSizeCheckbox(size, i))}
                  </Grid>
                </Item>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Colors
                <Button
                  variant="text"
                  onClick={handleOpenCustomColorModal}
                  size="small"
                  sx={{ float: "right" }}
                >
                  ADD CUSTOM COLOR
                </Button>{" "}
              </Typography>

              <Grid item xs={12}>
                <Item>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      maxWidth: "1040px",
                    }}
                  >
                    {localCustomFitPrintProduct.options.find(
                      (option) => option.type === "color"
                    ).values.map((color, i) => renderColorCheckbox(color, i))}
                  </Grid>
                </Item>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Variants
                <Button
                  variant="contained"
                  onClick={handleOpenAddVariantModal}
                  sx={{ marginLeft: "20px", width: "150px", float: "right" }}
                >
                  ADD VARIANTS
                </Button>{" "}
              </Typography>
              <Grid item xs={12}>
                <Item>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      maxWidth: "1040px",
                    }}
                  >
                  <Grid item xs={1.5}>
                    <Typography variant="body1">Size</Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography variant="body1">Color</Typography>
                  </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">SKU</Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                      <Typography variant="body1">Title</Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                      <Typography variant="body1">Price</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body1">Default</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body1">Enabled</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body1">Available</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body1"></Typography>
                    </Grid>
                    {localCustomFitPrintProduct.variants.map((variant, i) =>
                      renderVariantItem(variant, i)
                    )}
                  </Grid>
                </Item>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Item>xs=8</Item>
            </Grid>
            <Grid item xs={4}>
              <Item>xs=4</Item>
            </Grid>
            <Grid item xs={4}>
              <Item>xs=4</Item>
            </Grid>
            <Grid item xs={8}>
              <Item>xs=8</Item>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Button
        variant="contained"
        onClick={handleSubmit}
        sx={{ width: "150px", float: "right", marginRight: "-10px" }}
      >
        SAVE
      </Button>{" "}
      <input type="file" onChange={onChange} />
    </>
  );
};
export default CreateNewProduct;
